import {postData} from "./Post";

export const getWikiCategories = async (dispatch, sessionId) => {
    return postData({action: 'wiki', wikiUrlName: ''}, sessionId)
        .then(json => {
            const items = json;

            if (json.success) {
                dispatch({
                    type: 'SET_WIKI_CATEGORIES',
                    wikiCategories: json.data
                });
                return items;
            } else {
                if (json.error) {
                    dispatch({
                        type: 'SET_ERROR',
                        error: json.error
                    });
                }
                return [];
            }
        })
        .catch(error => {
            console.log(error);
        });
}

