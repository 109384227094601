import React, {createContext, useReducer} from "react";
import Reducer from './Reducer'


const initialState = {
    error:'',
    information:'',
    wochenplan:[],
    rezept:{},
    search:{},
    zutaten:[],
    wiki: {},
    wikiCategories: [],
    user:null,
    sessionId: '',
    settings: {},
    einkaufszettel: [],
    notizen: [],
    isDesktop: false,
    editRezept: {},
    accountLinks: [],
    initial: false,
};

const Store = ({children}) => {
    const oldState = JSON.parse(localStorage.getItem('state'));
    const initalValues = oldState !== null ? oldState : initialState;

    const [state, dispatch] = useReducer(Reducer, initalValues);

    if (state.sessionId === '') {
        let sessionId = localStorage.getItem('ssid');
        if (sessionId !== null && sessionId !== '') {
            dispatch({
                type: 'SET_SSID',
                sessionId: sessionId
            });
        }
    }
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};

export const Context = createContext(initialState);
export default Store;