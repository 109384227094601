import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../Store/Store";
import '../../drag.css';
import Grid from "@material-ui/core/Grid";
import {getWochenplan} from "../../api/getWochenplan";
import {getSearchRecipe} from "../../api/getSearchRecipe";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import {Link} from "react-router-dom";
import ChatRegister from "../Components/ChatRegister/ChatRegister";
import {Helmet} from "react-helmet";
import ModalContainer from "../Components/ModalContainer";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import Search from "./Search";

const useStyles = makeStyles((theme) => ({
    titleBar: {
        background:
            'linear-gradient(to top, rgba(111,100,68,1) 0%, ' +
            'rgba(111,100,68,0.6) 70%, rgba(111,100,68,0) 100%)',
        height: '10%',
        minHeight: '30px'
    },
    title: {
        lineHeight: '14px',
        fontSize: '13px',
        color: '#ffffff',
        textDecoration: 'none',
        whiteSpace: 'break-spaces',

        '& a': {
            color: '#ffffff',
            textDecoration: 'none',
        }
    },
    buttons: {
        // margin: '5px 0 0 0 ',
        margin: '10px 10px',
        // color: '#ffffff',
        fontWeight: '600',
        color: '#646f44 !important',
        borderColor: '#646f44 !important',
        '&:hover': {
            backgroundColor: '#465128',
        },
        '&:active': {
            backgroundColor: '#646f44',
        },
    },
}));

const Wochenplan = () => {
    const classes = useStyles();
    const [rezeptIdState, setRezeptIdState] = useState(0);
    const [tagIdState, setTagIdState] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [state, dispatch] = useContext(Context);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState('');
    const [isRezeptSuche, setIsRezeptSuche] = useState(true);
    const [moreInformation, setMoreInformation] = useState(localStorage.getItem('ssid') !== null);
    const loading = openModal && options.length === 0;


    useEffect(() => {
        getWochenplan(dispatch, state.sessionId);
        // eslint-disable-next-line
    }, []);

    const renew = (rezeptId, tagId) => {
        getWochenplan(dispatch, state.sessionId, {action: 'renew', rezeptId: rezeptId, tagId: tagId});
    };

    const addToCart = (rezeptId) => {
        getWochenplan(dispatch, state.sessionId, {action: 'addToCart', rezeptId: rezeptId});
    };

    const searchRecipe = (rezeptId, tagId) => {
        setRezeptIdState(rezeptId);
        setTagIdState(tagId);
        setOpenModal(true);
    };

    const changeRecipe = (id) => {
        getWochenplan(dispatch, state.sessionId, {
            action: 'renew_search',
            rezeptId: rezeptIdState,
            tagId: tagIdState,
            newRezeptId: id
        });
        setOpenModal(false);
    };

    useEffect(() => {
        let active = true;

        // if (!loading) {
        //     return undefined;
        // }
        getSearchRecipe(dispatch, search).then((result) => {
                if (active) {
                    setOptions(result);
                }
            }
        );

        return () => {
            active = false;
        };
        // eslint-disable-next-line
    }, [search]);

    return (
        <>
            <Helmet>
                <title>Dein persönlicher Essensplan - Plan Es(s)</title>
                <meta name="description"
                      content={"In wenigen Sekunden ist dein persönlicher Essensplan erstellt und gibt dir Essensvorschläge für deine Woche - Plan Es(s)"}/>
            </Helmet>
            <Grid container>
                <Grid item md={3}/>
                <Grid item md={6} xs={12} style={{listStyle: 'none'}}>
                    <h1>Essensplan</h1>
                    <div style={{textAlign: 'right', marginTop: '10px'}}><Link to={'addrecipe'} style={{
                        color: '#ffffff',
                        textDecoration: 'underline'
                    }}>Rezept hinzufügen</Link><br/></div>


                    <div style={{margin: (state.isDesktop ? '0' : '0 10px')}}>
                        {localStorage.getItem('ssid') === null &&
                        <>
                            Wenn du eingeloggt bist, stehen dir folgende Features zur Verfügung: <br/><br/>
                        </>
                        }
                        {localStorage.getItem('ssid') !== null &&
                        <>
                            <span onClick={() => {
                                setMoreInformation(!moreInformation)
                            }} style={{
                                textDecoration: 'underline',
                                color: '#ffffff',
                                cursor: 'pointer'
                            }}>Erklärung</span><br/>
                        </>
                        }
                        {!moreInformation &&
                        <>

                            Hier siehst du deinen persönlichen Essensplan.<br/>
                            Unter <Link to={'/settings'} style={{color: '#ffffff'}}>Einstellungen</Link> kannst du die
                            Art und Anzahl der Mahlzeiten pro Tag festlegen, ungewünschte Lebensmittel ausschließen und
                            deinen Plan mit anderen verbinden.<br/>
                            Mit einem Klick auf <SearchIcon/> kannst du ein spezielles Rezept suchen, auf welches du
                            Lust hast.<br/>
                            Mit dem <AutorenewIcon/> Symbol kannst du dir einen neuen Vorschlag anzeigen lassen, wenn du
                            mit dem aktuellen Rezept nicht zufrieden bist.<br/>
                            Außerdem kannst du mit <AddShoppingCart/> die Zutaten des Rezepts direkt auf deinen <Link
                            to={'/einkaufszettel'} style={{color: '#ffffff'}}>Einkaufszettel</Link> hinzufügen. <br/>
                            Viel spaß beim Planen!
                            <br/>
                            <br/>
                        </>
                        }

                        {state.wochenplan.length > 0 && state.wochenplan.map((t) => {
                            return <div key={Math.random()} style={{marginBottom: '30px'}}>
                                <Grid container>
                                    <Grid item xs={6}><h4>{t.category + ' ' + t.dayTime}</h4></Grid>
                                    <Grid item xs={6} style={{float: "right", textAlign: 'right', marginTop: '15px'}}>
                                        {localStorage.getItem('ssid') !== null && <>
                                            <IconButton
                                                title={'Rezept suchen'}
                                                onClick={() => searchRecipe(t.rezeptId, t.tagId)}
                                            >
                                                <SearchIcon/>
                                            </IconButton>
                                            <IconButton
                                                title={'Neues Rezept'}
                                                onClick={() => renew(t.rezeptId, t.tagId)}
                                            >
                                                <AutorenewIcon/>
                                            </IconButton>
                                            <IconButton
                                                title={'Zum Einkaufszettel hinzufügen'}
                                                onClick={() => addToCart(t.rezeptId)}
                                            >
                                                <AddShoppingCart/>
                                            </IconButton>
                                        </>
                                        }
                                    </Grid>

                                </Grid>
                                <Link to={'/rezept/' + t.urlName}>
                                    <ImageListItem
                                        className="draggable"
                                        style={{
                                            textAlign: 'center',
                                            backgroundImage: 'url(' + (t.bild !== null ? '/assets/' + t.bild : '"' + ((window.location.hostname === 'localhost' ? 'http://localhost/planess/public' : '') + t.user_image + '"')) + ')',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '100%',
                                            maxHeight: '450px',
                                            minHeight: (state.isDesktop ? '300px' : '150px'),
                                            backgroundPositionY: 'center',
                                        }}
                                    >
                                        <ImageListItemBar
                                            title={t.name}
                                            classes={{
                                                root: classes.titleBar,
                                                title: classes.title,
                                            }}
                                        />
                                    </ImageListItem>
                                </Link>
                            </div>
                        })}
                    </div>
                </Grid>
                <Grid item md={3}/>
            </Grid>
            <ModalContainer
                open={openModal}
                handleClose={() => setOpenModal(!openModal)}
                Title={'Rezept Suche'}
            >
                <Button variant={"outlined"} className={classes.buttons} onClick={() => setIsRezeptSuche(true)}>Suche
                    nach Namen</Button>
                <Button variant={"outlined"} className={classes.buttons}
                        style={{margin: (state.isDesktop ? '0 20px' : '10px 0')}}
                        onClick={() => setIsRezeptSuche(false)}>Suche mit Zutaten</Button>
                {isRezeptSuche ?
                    <>
                        <br/><br/>
                         <>Du suchst etwas bestimmtes oder willst einfach mal wieder dein
                            Lieblingsgericht essen?
                            Gib hier den Namen des Rezepts ein und füge es direkt dem Wochenplan hinzu.</><br/>
                        <Autocomplete
                            id="zutat1"
                            options={options}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                                changeRecipe(value.id)
                            }}
                            loading={loading}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Nach Rezept suchen"
                                    onChange={(event) => setSearch(event.target.value)}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        /><br/><br/>
                    </>
                    :
                    <>
                        <Search onChange={changeRecipe}/>
                    </>
                }
            </ModalContainer>
            <ChatRegister/>
        </>
    );
};

export default Wochenplan;