import {postData} from "./Post";

export const addRecipe = async (dispatch, recipe) => {
    return postData({action: 'addRecipe', recipe: recipe})
        .then(json => {
            const items = json;

            if (json.success) {
                dispatch({
                    type: 'SET_INFO',
                    information: "Rezept erfolgreich gespeichert!"
                });

            } else {
                if (json.error) {
                    dispatch({
                        type: 'SET_ERROR',
                        error: json.error
                    });
                } else {
                    dispatch({
                        type: 'SET_ERROR',
                        error: "Fehler bei dem Speichern des Rezepts!"
                    });
                }
            }
            return items;
        })
        .catch(error => {
            console.log(error);
        });
}

