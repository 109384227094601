import {postData} from "./Post";

export const getWiki = async (dispatch, wikiUrlName, dev = true, sessionId) => {
    return postData({action: 'wiki', wikiUrlName: wikiUrlName}, sessionId)
        .then(json => {
            const items = json;

            if (json.success) {
                dispatch({
                    type: 'SET_WIKI_ENTRY',
                    wiki: json.data
                });
                return items;
            } else {
                if (json.error) {
                    dispatch({
                        type: 'SET_ERROR',
                        error: json.error
                    });
                    return [];
                }
            }
        })
        .catch(error => {
            console.log(error);
        });
}

