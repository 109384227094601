import {postData} from "./Post";

export const getSearchRecipe = async (dispatch, name) => {
    return postData({action: 'searchRecipeName', name: name})
        .then(json => {

            if (json.data.found > 0) {
                return json.data.result;
            }
            if (!json.success && json.error) {
                dispatch({
                    type: 'SET_ERROR',
                    error: json.error
                });
            }
            return [];
        })
        .catch(error => {
            console.log(error);
        });
}

