import React, {useContext, useEffect} from "react";
import {Context} from "../../Store/Store";
import {Redirect} from "react-router-dom";
import Input from "@material-ui/core/Input";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from '@material-ui/core/MenuItem';
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import {getSettings} from "../../api/getSettings";
import BoolButton from "../Components/BoolButton";
import Button from '@material-ui/core/Button';
import {getLink} from "../../api/getLink";
import TextField from "@material-ui/core/TextField";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Helmet} from "react-helmet";


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    buttons: {
        // margin: '5px 0 0 0 ',
        margin: '10px 10px',
        color: '#ffffff',
        fontWeight: '600',
        backgroundColor: '#646f44',
        '&:hover': {
            backgroundColor: '#465128',
        },
        '&:active': {
            backgroundColor: '#646f44',
        },
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    inputField: {
        textDecoration: 'none',
        margin: '10px 10px',
        width: '100%'
    },
}));

const Settings = () => {
    const [state, dispatch] = useContext(Context);
    const classes = useStyles();
    let startEssensstil = 'Flexitarisch';
    if (state.settings.settings && parseInt(state.settings.settings.Vegan) === 1) {
        startEssensstil = 'Vegan';
    } else if (state.settings.settings && parseInt(state.settings.settings.Vegetarisch) === 1) {
        startEssensstil = 'Vegetarisch';
    }
    const [essensStilVar, setEssensStilVar] = React.useState(startEssensstil);
    const [allergienVar, setAllergienVar] = React.useState(state.settings.settings && state.settings.settings.allergien !== null ? state.settings.settings.allergien : []);
    const [personenzahlVar, setPersonenzahlVar] = React.useState(state.settings.settings ? state.settings.settings.personenzahl : 2);
    const [mahlzeiten, setMahlzeiten] = React.useState(state.settings.settings ? state.settings.settings.mahlzeiten : {
        "Samstag": {
            "abends": "3",
            "morgens": "1",
            "mittags": "3"
        },
        "Sonntag": {"abends": "3", "morgens": "1", "mittags": "3"},
        "Montag": {"morgens": "1", "mittags": "3", "abends": "3"},
        "Dienstag": {"morgens": "1", "mittags": "3", "abends": "3"},
        "Mittwoch": {"morgens": "1", "mittags": "3", "abends": "3"},
        "Donnerstag": {"morgens": "1", "mittags": "3", "abends": "3"},
        "Freitag": {"morgens": "1", "mittags": "3", "abends": "3"}
    });

    useEffect(() => {
        if (state.settings === {}) {
            getSettings(dispatch, []).then(() => {
                let startEssensstil = 'Flexitarisch';
                if (parseInt(state.settings.Vegan) === 1) {
                    startEssensstil = 'Vegan';
                } else if (parseInt(state.settings.Vegetarisch) === 1) {
                    startEssensstil = 'Vegetarisch';
                }
                setPersonenzahlVar(state.settings.personenzahl);
                setEssensStilVar(startEssensstil);
                setAllergienVar(state.settings.allergien);
                setMahlzeiten(state.settings.settings.mahlzeiten);
            });
        }
        if (state.accountLinks.length === 0) {
            getLink(dispatch);
        }
        // eslint-disable-next-line
    }, []);

    const handleChangeEssensStil = (event) => {
        setEssensStilVar(event.target.value);
    };
    const handleChangePersonen = (event) => {
        setPersonenzahlVar(event.target.value);
    };
    // const handleChangeAllergien = (event) => {
    //     setAllergienVar(event.target.value);
    // };

    const addAllergie = (ID) => {
        let index = allergienVar.indexOf(ID);
        let temp = allergienVar;
        if (index > -1) {
            temp.splice(index, 1);
            setAllergienVar(temp);
        } else {
            temp.push(ID);
            setAllergienVar(temp);
        }

    };

    const save = () => {
        getSettings(dispatch, {
            allergien: allergienVar,
            essensstil: essensStilVar,
            personenzahl: personenzahlVar
        }).then((returnValue) => {
            if (returnValue) {
                dispatch({
                    type: 'SET_INFO',
                    information: "Einstellungen erfolgreich gespeichert!"
                });

            } else {
                dispatch({
                    type: 'SET_ERROR',
                    error: "Fehler bei dem Speichern der Einstellungen!"
                });

            }
        });
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: '100%',
            },
        },
    };
    const essensStil = [
        'Flexitarisch',
        'Vegetarisch',
        'Vegan',
    ];
    const changeMahlzeitPlan = (tag, mahlzeit, zeit) => {
        let zeitObjekt = {};
        // eslint-disable-next-line default-case
        switch (zeit) {
            case 'morgens':
                zeitObjekt = {morgens: mahlzeit};
                break;
            case 'mittags':
                zeitObjekt = {mittags: mahlzeit};
                break;
            case 'abends':
                zeitObjekt = {abends: mahlzeit};
                break;
        }
        // eslint-disable-next-line default-case
        switch (tag) {
            case 'Montag':
                setMahlzeiten({...mahlzeiten, Montag: {...mahlzeiten.Montag, ...zeitObjekt}});
                break;
            case 'Dienstag':
                setMahlzeiten({...mahlzeiten, Dienstag: {...mahlzeiten.Dienstag, ...zeitObjekt}});
                break;
            case 'Mittwoch':
                setMahlzeiten({...mahlzeiten, Mittwoch: {...mahlzeiten.Mittwoch, ...zeitObjekt}});
                break;
            case 'Donnerstag':
                setMahlzeiten({...mahlzeiten, Donnerstag: {...mahlzeiten.Donnerstag, ...zeitObjekt}});
                break;
            case 'Freitag':
                setMahlzeiten({...mahlzeiten, Freitag: {...mahlzeiten.Freitag, ...zeitObjekt}});
                break;
            case 'Samstag':
                setMahlzeiten({...mahlzeiten, Samstag: {...mahlzeiten.Samstag, ...zeitObjekt}});
                break;
            case 'Sonntag':
                setMahlzeiten({...mahlzeiten, Sonntag: {...mahlzeiten.Sonntag, ...zeitObjekt}});
                break;
        }
    };

    const saveMahlzeitPlan = () => {
        getSettings(dispatch, {
            mahlzeiten: mahlzeiten,
        }).then((returnValue) => {
            if (returnValue) {
                dispatch({
                    type: 'SET_INFO',
                    information: "Einstellungen erfolgreich gespeichert!"
                });

            } else {
                dispatch({
                    type: 'SET_ERROR',
                    error: "Fehler bei dem Speichern der Einstellungen!"
                });

            }
        });
    }

    const Wochentage = [
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
        'Sonntag',
    ];

    let wochenplanTagesSettings = [];
     Wochentage.forEach((value) => {
         wochenplanTagesSettings.push(<>
            <Grid item xs={11}>
                <br/><br/><strong>{value}</strong>
                <br/>Morgens:<br/>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="mahlzeit" name={"mahlzeit"+value}
                                value={mahlzeiten[value].morgens}
                                onChange={(event) => changeMahlzeitPlan(value, event.target.value, 'morgens')}>
                        <Grid container>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel value="1" control={<Radio/>} label="Frühstück"/>

                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControlLabel value="3" control={<Radio/>}
                                                  label="Warme Mahlzeit"/> </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControlLabel value="2" control={<Radio/>} label="Snack"/>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControlLabel value="5" control={<Radio/>}
                                                  label="Kuchen"/> </Grid>
                            <Grid item xs={12} md={1}>
                                <FormControlLabel value="0" control={<Radio/>}
                                                  label="Nichts"/> </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>
                <br/>Mittags:<br/>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="mahlzeit" name={"mahlzeit"+value}
                                value={mahlzeiten[value].mittags}
                                onChange={(event) => changeMahlzeitPlan(value, event.target.value, 'mittags')}>
                        <Grid container>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel value="1" control={<Radio/>} label="Frühstück"/>

                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControlLabel value="3" control={<Radio/>}
                                                  label="Warme Mahlzeit"/> </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControlLabel value="2" control={<Radio/>} label="Snack"/>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControlLabel value="5" control={<Radio/>}
                                                  label="Kuchen"/> </Grid>
                            <Grid item xs={12} md={1}>
                                <FormControlLabel value="0" control={<Radio/>}
                                                  label="Nichts"/> </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>
                <br/>Abends:<br/>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="mahlzeit" name={"mahlzeit"+value} value={mahlzeiten[value].abends}
                                onChange={(event) => changeMahlzeitPlan(value, event.target.value, 'abends')}>
                        <Grid container>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel value="1" control={<Radio/>} label="Frühstück"/>

                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControlLabel value="3" control={<Radio/>}
                                                  label="Warme Mahlzeit"/> </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControlLabel value="2" control={<Radio/>} label="Snack"/>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControlLabel value="5" control={<Radio/>}
                                                  label="Kuchen"/> </Grid>
                            <Grid item xs={12} md={1}>
                                <FormControlLabel value="0" control={<Radio/>}
                                                  label="Nichts"/> </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>
            </Grid>
        </>);
    });

    // const allergie = [
    //     'Gluten',
    //     'Laktose',
    //     'Histamin',
    // ];
    const names = state.settings.kategorien ? state.settings.kategorien : [];
    return (
        <>
            {localStorage.getItem('ssid') === null &&
            <Redirect to={{
                pathname: "/login",
            }}/>}

            <Helmet>
                <title>Einstellungen - Plan Es(s)</title>
                <meta name="description"
                      content={"Deine Einstellungen bei Plan Es(s), erstelle ganz einfach deinen individuellen Essensplan."}/>
            </Helmet>

            <Grid container>
                <Grid item md={3}/>
                <Grid item xs={11} md={6}>
                    <h1>Einstellungen</h1><br/>
                    <FormControl className={classes.formControl}>
                        <InputLabel style={{fontSize: '15px'}}>Ich ernähre mich</InputLabel>
                        <Select
                            multiple={false}
                            value={essensStilVar}
                            onChange={handleChangeEssensStil}
                            input={<Input id="selectStil"/>}
                            MenuProps={MenuProps}
                        >
                            {essensStil.map((name) => (
                                <MenuItem key={name} value={name}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl><br/><br/>

                    <FormControl className={classes.formControl}>
                        <InputLabel style={{fontSize: '15px'}}>Anzahl der Portionen</InputLabel>
                        <Input type={'number'} placeholder={'Anzahl der Portionen'} value={personenzahlVar}
                               onChange={handleChangePersonen}/>
                    </FormControl><br/><br/>

                    <InputLabel style={{fontSize: '15px'}}>Ich möchte folgendes (nicht) essen:</InputLabel><br/>
                    {names.map((item) => (
                        <BoolButton value={item.Name} key={'Allergie' + item.ID}
                                    onClick={() => addAllergie(parseInt(item.ID))}
                                    bool={allergienVar.includes(parseInt(item.ID))}/>
                    ))}
                    <br/><br/><br/>
                    <Button onClick={() => save()} className={classes.buttons}
                            style={{padding: '10px'}}>Speichern</Button>


                    <br/> <br/>

                    <h3>Essensplan Einstellungen</h3><br/>
                    Bei einer Änderung wird dein aktueller Plan gelöscht!


                    <Grid container style={{overflowX: 'auto', maxHeight: '400px', marginLeft: '5px'}}>

                            {wochenplanTagesSettings}

                    </Grid>
                    <Button className={classes.buttons} onClick={saveMahlzeitPlan}>Plan speichern</Button>

                    <br/> <br/>
                    Du kannst deinen Essensplan, Notizen und Einkaufszettel mit anderen teilen, zum Beispiel mit deinem
                    Partner oder deiner Familie.<br/><br/>
                    {state.accountLinks !== [] && typeof state.accountLinks !== 'undefined' && <>
                        {state.accountLinks.map((link) => {
                            return <>
                                <TextField value={link}
                                           color={'primary'}
                                           className={classes.inputField}
                                           variant="outlined"/>
                                <br/><br/>
                            </>
                        })}
                    </>}
                    <br/>
                    <Button className={classes.buttons} onClick={() => getLink(dispatch, null, true)}>Link
                        erstellen</Button>
                </Grid>
                <Grid item md={3}/>

            </Grid>

        </>
    );
};

export default Settings;