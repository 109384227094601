import React, {useContext} from "react";
import {Context} from "../../Store/Store";
import Grid from "@material-ui/core/Grid";

const ErrorHandler = () => {
    const [state, dispatch] = useContext(Context);

    if (state.error !== '')  {
        setTimeout(function(){
                   dispatch({
                       type: 'SET_ERROR',
                       error: ""
                   });
               },3000);
    }
    if (state.information !== '')  {
        setTimeout(function(){
            dispatch({
                type: 'SET_INFO',
                information: ""
            });
        },2000);
    }
    return (
        <>
            {state.error !== '' &&
            <Grid container>
                <Grid item md={2} />
                <Grid item xs={12} md={8} style={{padding:'20px 40px', margin:'20px 5px', backgroundColor:'rgba(200,0,0,0.18)',  borderRadius:'10px', color:'#b10000', fontWeight:'600'}}>
                    {state.error}
                </Grid>
                <Grid item md={2} />
            </Grid>
            }
            {state.information !== '' &&
            <Grid container>
                <Grid item md={2} />
                <Grid item xs={12} md={8} style={{padding:'20px 40px', margin:'20px 5px', backgroundColor:'rgba(18,255,1,0.25)',  borderRadius:'10px', color:'#465128', fontWeight:'600'}}>
                    {state.information}
                </Grid>
                <Grid item md={2} />
            </Grid>
            }
        </>
    );
};

export default ErrorHandler;