import React, {useContext, useEffect} from "react";
import {getRezept} from "../../api/getRezept";
import {Context} from "../../Store/Store";
import Grid from "@material-ui/core/Grid";
import ChatRegister from "../Components/ChatRegister/ChatRegister";
// import {Redirect} from "react-router-dom";
import {getWochenplan} from "../../api/getWochenplan";
import IconButton from "@material-ui/core/IconButton";
import AddShoppingCart from "@material-ui/icons/AddShoppingCart";
import '../../fancy.scss';
import {Helmet} from "react-helmet";

const Rezept = ({match}) => {
    const [state, dispatch] = useContext(Context);
    const urlName = match.params.id;

    useEffect(() => {
        if (!state.rezept[match.params.id]) {
            getRezept(dispatch, match.params.id, state.sessionId);
        }
        // eslint-disable-next-line
    }, []);

    function createMarkup() {
        return {__html: state.rezept[urlName].rezept};
    }

    const addToCart = () => {
        getWochenplan(dispatch, state.sessionId, {action: 'addToCart', rezeptId: state.rezept[urlName].ID});
    };

    const personen = state.user && state.user.personenzahl ? state.user.personenzahl : 2;
    window.scrollTo(0,0);

    return (
        <>
            {/*{(state.user === null)&&*/}
            {/*<Redirect to={{*/}
            {/*    pathname: "/login",*/}
            {/*    state: {referrer: match.url}*/}
            {/*}}/>}*/}
            {state.rezept && state.rezept[urlName] && state.rezept[urlName].ID > 0 &&
            <Grid container style={{padding: '20px 40px 20px 20px'}}>
                <Helmet>
                    <title>{state.rezept[urlName].Name} Rezept - Plan Es(s)</title>
                    <meta name="description" content={state.rezept[urlName].Name + " Rezept bei Plan Es(s), erstelle ganz einfach deinen eigenen Essensplan."} />
                </Helmet>
                <Grid item md={2}/>
                <Grid item xs={12} md={3}>
                    <img src={state.rezept[urlName].bilder !== null ? '/assets/' + state.rezept[urlName].bilder : ((window.location.hostname === 'localhost' ?'http://localhost/planess/public' :'') + state.rezept[urlName].user_image)}
                         width={state.isDesktop ? '80%' : '100%'}
                         alt={state.rezept[urlName].Name + 'Rezeptbild'}
                         height={state.isDesktop ? '80%' : '100%'}
                         style={{maxHeight:'700px'}}
                         id={'recipeImage'}/>
                </Grid>
                <Grid item md={4} xs={12} style={{paddingLeft: '20px'}}>
                    <h1>{state.rezept[urlName].Name}</h1>
                    <div style={{textAlign: 'right', float: 'right'}}>
                        <IconButton
                            title={'Zum Einkaufszettel hinzufügen'}
                            onClick={() => addToCart()}
                        >
                            <AddShoppingCart/>
                        </IconButton>
                    </div>
                    Für {personen} Portionen:
                    <ul>
                        {state.rezept[urlName].Zutaten.map((item) => {
                            return <li key={item.name}>{item.menge * personen / 2} {item.einheit} {item.name}</li>
                        })}
                    </ul>
                </Grid>
                <Grid item md={3}/>

                <Grid item md={2}/>
                <Grid item xs={12} md={8} className={'rezept'}>
                    {/*{state.rezept[urlName].bilder !== null ?*/}
                        <div dangerouslySetInnerHTML={createMarkup()}/>
                        {/*// :*/}
                    {/*// <pre style={{whiteSpace:'pre-wrap'}}>{state.rezept[urlName].rezept}</pre>}*/}

                </Grid>
                <Grid item md={2}/>
                <Grid item md={2}/>
                <Grid item xs={12} md={8} className={'rezept'}>
                    <br />
                    <br />
                   Kalorien pro Portion: ~ {new Intl.NumberFormat('de-DE', { style: 'decimal', maximumSignificantDigits: 2 }).format(state.rezept[urlName].kalorien / personen)} kcal<br />
                   Eiweiss pro Portion: ~ {new Intl.NumberFormat('de-DE', { style: 'decimal', maximumSignificantDigits: 2 }).format(state.rezept[urlName].eiweiss / personen)} g<br />
                    Kohlenhydrate pro Portion: ~ {new Intl.NumberFormat('de-DE', { style: 'decimal', maximumSignificantDigits: 2 }).format(state.rezept[urlName].kohlenhydrate / personen)} g<br />
                    Fett pro Portion: ~ {new Intl.NumberFormat('de-DE', { style: 'decimal', maximumSignificantDigits: 2 }).format(state.rezept[urlName].fett / personen)} g<br />
                   Preis pro Portion: ca. {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(state.rezept[urlName].minPreis / personen)} - {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(state.rezept[urlName].maxPreis / personen)}<br />
                    {state.rezept[urlName].Zeitaufwand > 0 && <>Zubereitungszeit: {state.rezept[urlName].Zeitaufwand} min</>}
                </Grid>
                <Grid item md={2}/>
                <Grid item md={2}/>
                <Grid item xs={12} md={8} className={'rezept'}>
                    <div style={{marginTop:'100px'}}><small><sup>1</sup>) Affiliate-Link, wenn du auf so einen Werbe-Link klickst und über diesen Link einkaufst, bekomme ich von dem betreffenden Online-Shop oder Anbieter eine Provision. Für dich verändert sich der Preis nicht.</small></div>
                </Grid>
                <Grid item md={2}/>
            </Grid>
            }
            <ChatRegister/>
        </>
    );
};

export default Rezept;