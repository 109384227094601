import {postData} from "./Post";

export const getWochenplan = async (dispatch, sessionId, mutation = {}) => {
    return postData({action: 'wochenplan', mutation: mutation}, sessionId)
        .then(json => {
            if (json.success) {
                dispatch({
                    type: 'SET_WOCHENPLAN',
                    wochenplan: json.data
                });
                return json;
            } else {
                if (json.error) {
                    dispatch({
                        type: 'SET_ERROR',
                        error: json.error
                    });
                }
                return [];
            }
        })
        .catch(error => {
            console.log(error);
        });
}

