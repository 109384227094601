import {postData} from "./Post";

export const getRezept = async (dispatch, rezeptUrlName, sessionId) => {
    return postData({action: 'rezept', rezeptUrlName: rezeptUrlName}, sessionId)
        .then(json => {
            const items = json;

            if (json.success) {
                dispatch({
                    type: 'SET_REZEPT',
                    rezept: json.data
                });
                return items;
            }
            if (json.error) {
                dispatch({
                    type: 'SET_ERROR',
                    error: json.error
                });
                return [];
            }
        })
        .catch(error => {
            console.log(error);
        });
}

