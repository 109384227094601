import React, {useContext, useState} from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PersonIcon from '@material-ui/icons/Person';
import {makeStyles} from "@material-ui/core/styles";
import {saveRegister} from "../../../api/saveRegister";
import {Context} from "../../../Store/Store";

const useStyles = makeStyles((theme) => ({
    buttons: {
        margin: '5px 0 0 0 ',
        backgroundColor: '#646f44',
        color: '#ffffff',
        fontWeight: '500',
        '&:hover': {
            backgroundColor: '#465128',
        },
        '&:active': {
            backgroundColor: '#646f44',
        },
    },
}));

const Register = () => {
    const [state, dispatch] = useContext(Context);
    const classes = useStyles();
    const [currentState, setCurrentState] = useState(0);
    const [dataArray, setDataArray] = useState({});

    const currentText = [
        'Hey, kann ich Dir helfen? Möchtest Du Deinen kostenlosen, persönlichen Essensplan erstellt bekommen?',
        'Dann hab ich ein paar Fragen. Welche Ernährungsart bevorzugst Du?',
        'Für wie viele Personen möchtest Du planen?',
        'Willst du eine Maximale Zubereitungszeit festlegen? Dadurch wären weniger Rezepte möglich.',
        'Wie viele Minuten pro Gericht willst du einplanen? (Gib 0 ein für keine Begrenzung)',
        "Für Deinen persönlichen Account brauch ich noch Deine E-Mail Adresse? Ich schicke dir auch kein Spam 😉. Mehr dazu unter Datenschutz",
        'Welches Passwort willst du verwenden? Es sollte mehr als 6 Zeichen haben damit es Dein Account bleibt.',
        'Danke für die ganzen Informationen, darf ich Dich so registrieren?',
        'Vielen Dank für Deine Registrierung. Bitte bestätige Deine E-Mail Adresse jetzt.',
    ];

    const keyPress = (e, id) => {
        if (parseInt(e.keyCode) === 13) {
            getStateText(e.target.value, id);
            // put the login here
        }
    }


    const getStateText = (data, change) => {
        let temp = '';
        switch (change) {
            case 0:
                setDataArray({...dataArray, start: data});
                setCurrentState(1);
                break;
            case 1:
                setDataArray({...dataArray, variant: data});
                setCurrentState(2);
                break;
            case 2:
                temp = parseInt(data);
                if (typeof temp === 'number' && temp > 0) {
                    setDataArray({...dataArray, person: temp});
                    setCurrentState(3);
                }
                break;
            case 3:
                setDataArray({...dataArray, maxTime: data});
                setCurrentState(data ? 4 : 5);
                break;
            case 4:
                temp = parseInt(data);
                if (typeof temp === 'number' && (temp === 0 || temp > 15)) {
                    setDataArray({...dataArray, time: temp});
                    setCurrentState(5);
                }
                break;
            case 5:
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (data.length > 8 && re.test(data)) {
                    setDataArray({...dataArray, email: data});
                    setCurrentState(6);
                }
                break;
            case 6:
                let pass1 = document.getElementById('pass1').value;
                let pass2 = document.getElementById('pass2').value;
                if (pass1 === pass2 && pass1.length > 6) {
                    setDataArray({...dataArray, password1: pass1, password2: pass2});
                    setCurrentState(7);

                }

                break;
            case 7:
                saveRegister(dispatch, dataArray, state.sessionId).then(
                    () => {
                        setCurrentState(8);
                    }
                );
                break;
            default:
                break;
        }
    };

    const currentAction = [
        <Button color={'primary'} className={classes.buttons} variant="contained"
                onClick={() => getStateText(true, 0)}>Ja</Button>,
        <>
            <Button color={'primary'} className={classes.buttons} variant="contained"
                    onClick={() => getStateText('normal', 1)}>Keine Einschränkung</Button><br/>
            <Button color={'primary'} className={classes.buttons} variant="contained"
                    onClick={() => getStateText('vegetarisch', 1)}>Vegetarisch</Button><br/>
            <Button color={'primary'} className={classes.buttons} variant="contained"
                    onClick={() => getStateText('vegan', 1)}>Vegan</Button><br/>
            <Button color={'primary'} className={classes.buttons} variant="contained"
                    onClick={() => getStateText('glutenfrei', 1)}>Glutenfrei</Button><br/>
            <Button color={'primary'} className={classes.buttons} variant="contained"
                    onClick={() => getStateText('laktosefrei', 1)}>Laktosefrei</Button><br/>
        </>,
        <TextField color={'primary'} type={'number'} placeholder={'Personen'} onKeyDown={(event) => keyPress(event, 2)}
                   onBlur={(event) => getStateText(event.target.value, 2)}/>,
        <>
            <Button color={'primary'} className={classes.buttons} variant="contained"
                    onClick={() => getStateText(true, 3)}>Ja</Button>
            <br/>
            <Button color={'primary'} className={classes.buttons} variant="contained"
                    onClick={() => getStateText(false, 3)}>Nein</Button>
        </>,
        <TextField color={'primary'} type={"number"} placeholder={'Minuten'} onKeyDown={(event) => keyPress(event, 4)}
                   onBlur={(event) => getStateText(event.target.value, 4)}/>,
        <TextField color={'primary'} type={'email'} placeholder={'Deine E-Mail'}
                   onKeyDown={(event) => keyPress(event, 5)} onBlur={(event) => getStateText(event.target.value, 5)}/>,
        <>
            <TextField color={'primary'} type={'password'} placeholder={'Dein Passwort'} id={'pass1'}
                       onKeyDown={(event) => keyPress(event, 6)}
                       onBlur={(event) => getStateText(event.target.value, 6)}/>
            <TextField color={'primary'} type={'password'} placeholder={'Wiederhol es bitte'} id={'pass2'}
                       onKeyDown={(event) => keyPress(event, 6)}
                       onBlur={(event) => getStateText(event.target.value, 6)}/>
        </>,
        <Button color={'primary'} className={classes.buttons} variant="contained"
                onClick={() => getStateText(true, 7)}>Registrieren</Button>,
        <></>,
    ];

    // if (state.sessionId !== '') {
    //     return <></>;
    // }
    return (
        <>
            <Grid container>
                <Grid item md={3}/>
                <Grid item xs={12} md={6}>
                    <Grid container style={{padding: '20px'}}>
                        {currentAction.map((item, key) => {
                            // let realKey = currentAction.length >= key ? currentAction.length - key - 1 : 0;
                            if (key > currentState || (currentState > 3 && key === 4 && !dataArray.maxTime)) {
                                return <></>;
                            }
                            if (currentState === 8 && key <= 7) {
                                return <></>;
                            }
                            return <>
                                <Grid item xs={2}>
                                    <PersonIcon style={{
                                        width: '69%',
                                        borderRadius: '100px',
                                        marginTop: '10px',
                                        border: '1px solid #CCCCCC'
                                    }}/>
                                </Grid>
                                <Grid item xs={10} style={{
                                    marginLeft: '-5px',
                                    borderRadius: '6px',
                                    border: '1px solid #cccccc',
                                    margin: '10px 0 5px 0',
                                    padding: '4px 3px'
                                }}>
                                    {currentText[key]}
                                </Grid>
                                <Grid item xs={12}>
                                    {item}
                                </Grid>
                            </>
                        })}
                    </Grid>
                </Grid>
                <Grid item md={3}/>
            </Grid>
        </>
    );
};

export default Register;