import React, {useState} from "react";
import Chip from "@material-ui/core/Chip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Done} from '@material-ui/icons';
import {Clear} from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}));

const BoolButton = ({ value, bool, onClick}) => {
    const classes = useStyles();
    const [isBool, setIsBool] = useState(bool);
    return (
        <>
            <Chip
                key={'boolbutton'+value} label={value} icon={ !isBool ? <Done /> : <Clear/>}
                className={classes.chip} onClick={() => {setIsBool(!isBool); onClick()}}
                style={{backgroundColor: (!isBool ? 'rgba(18,255,1,0.33)' : 'rgba(255,5,12,0.33)'
                        ) }}
            />
        </>
    );
};

export default BoolButton;