import React, {useContext, useState} from "react";
import {Context} from "../../Store/Store";
import {forgotPassword as sendForgotPassword} from "../../api/forgotPassword";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Redirect, useLocation, Link} from "react-router-dom";
import ChatRegister from "../Components/ChatRegister/ChatRegister";

const useStyles = makeStyles((theme) => ({
    buttons: {
        margin: '5px 0 0 0 ',
        backgroundColor: '#646f44',
        color:'#ffffff',
        fontWeight:'500',
        '&:hover': {
            backgroundColor: '#465128',
        },
        '&:active': {
            backgroundColor: '#646f44',
        },
    },
}));

const ForgotPassword = ({match}) => {
    const [state, dispatch] = useContext(Context);
    const classes = useStyles();
    const [dataArray, setDataArray] = useState({});
    const location = useLocation();


    const forgotPassword = () => {
        sendForgotPassword(dispatch,dataArray);
    }

    const onKeyUpHandle = (event) => {
        if (event.key === 'Enter') {
            sendForgotPassword(dispatch,dataArray);
        }
    }

    if (match && match.params && match.params.id && match.params.email && !dataArray.id) {
        setDataArray({...dataArray, 'id': match.params.id, email: match.params.email})
    }

    const redirectTo = location.state && location.state.referrer ? location.state.referrer : '/essensplan';
    return (
        <>
            {localStorage.getItem('ssid') !== null &&
            <Redirect to={redirectTo} />}
            <Grid container>
                <Grid item xs={12} style={{textAlign:"center", padding:30}}>
                    Falls du dein Passwort vergessen hast kannst du hier dies zurück setzen.
                    <br />
                    <br />
                    {match && match.params && match.params.id ?
                        <>
                            <TextField color={'primary'} type={'password'} placeholder={'Passwort'}
                                       style={{width:'50vw'}}
                                       onChange={(event) =>
                                       {setDataArray({...dataArray, 'password': event.target.value})}}
                                       onKeyUp={onKeyUpHandle}
                            /><br/><br/>
                            <TextField color={'primary'} type={'password'} placeholder={'Passwort wiederholen'}
                                       style={{width:'50vw'}}
                                       onChange={(event) =>
                                       {setDataArray({...dataArray, 'passwordRetype': event.target.value})}}
                                       onKeyUp={onKeyUpHandle}
                            /><br/><br/>
                            <Button color={'primary'} className={classes.buttons} variant="contained"
                                    onClick={() => forgotPassword()}
                            >Passwort ändern</Button>
                        </>
                        : <><TextField color={'primary'} type={'email'} placeholder={'Email'}
                               style={{width:'50vw'}}
                               onChange={(event) =>
                               {setDataArray({...dataArray, 'email': event.target.value})}}
                               onKeyUp={onKeyUpHandle}
                    /><br/><br/>
                        <Link to={'/register'} style={{color:'#ffffff', textDecoration:'none', marginLeft: (state.isDesktop ? '35vw' : '15vw')}}>Registrieren</Link>
                    <br/><br/>
                    <Button color={'primary'} className={classes.buttons} variant="contained"
                            onClick={() => forgotPassword()}
                    >Passwort vergessen</Button>
                    </>}
                </Grid>
            </Grid>
            <ChatRegister/>
        </>
    );
};

export default ForgotPassword;