import {postData} from "./Post";

export const getSearch = async(dispatch, zutaten, sessionId) => {
    return postData({action: 'getSearch', zutaten: zutaten},sessionId)
        .then(json => {
            const items = json;

            if (json.success) {
                dispatch({
                    type: 'SAVE_SEARCH_RESULT',
                    search: json.data
                });
            } else {
                if (json.error) {
                    dispatch({
                        type: 'SET_ERROR',
                        error: json.error
                    });
                }
            }
            return items;
        })
        .catch(error => {
            console.log(error);
        });
}

