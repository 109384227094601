import React, {useContext, useEffect} from "react";
import {getWiki} from "../../api/getWiki";
import {Context} from "../../Store/Store";
import {getWikiCategories} from "../../api/getWikiCategories";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import ChatRegister from "../Components/ChatRegister/ChatRegister";
import {Helmet} from 'react-helmet';

const Wiki = ({match}) => {
    const [state, dispatch] = useContext(Context);

    useEffect(() => {
        if (!!match && !!match.params && !!match.params.id && match.params.id !== 'veggie-infos') {
            if (!state.wiki[match.params.id]) {
                getWiki(dispatch, match.params.id, state.sessionId);
            }
        } else {
            if (state.wikiCategories.length === 0) {
                getWikiCategories(dispatch, state.sessionId);
            }
        }
        // eslint-disable-next-line
    }, [match]);

    function createFirstMarkup() {

        return {__html: state.wiki[match.params.id].text1};
    }

    function createMarkup() {
        return {__html: state.wiki[match.params.id].text2 + '<br />' + state.wiki[match.params.id].text3 + '<br />' + state.wiki[match.params.id].text4 + '<br />'};
    }

    return (
        <>
            {!!match && state.wiki[match.params.id] ?
                <>
                    <Helmet>
                        <title>{state.wiki[match.params.id].name} - Plan Es(s)</title>
                        <meta name="description"
                              content={"Informationen rund um " + state.wiki[match.params.id].name + " und Plan Es(s)"}/>
                    </Helmet>
                    <Grid container>
                        <Grid item md={3}/>
                        <Grid item xs={12} md={6} >
                            <Grid container style={{padding:(state.isDesktop ? '' : '0 10px')}}>
                                <Grid item md={3}>
                                    {state.wiki[match.params.id].bild &&
                                    <img src={'/assets/' + state.wiki[match.params.id].bild}
                                         alt={state.wiki[match.params.id].name + ' Bild'}
                                         style={{marginTop:'30px'}}
                                         width={'100%'}/>
                                    }
                                </Grid>
                                <Grid item md={9} style={{paddingLeft: '25px'}}>
                                    <h1>{state.wiki[match.params.id].name}</h1>
                                    <div dangerouslySetInnerHTML={createFirstMarkup()}/>
                                </Grid>
                                {state.wiki[match.params.id].text2 &&
                                <Grid item xs={12}>
                                    <div dangerouslySetInnerHTML={createMarkup()}/>
                                </Grid>

                                }
                            </Grid>
                            <Grid item md={3}/>
                        </Grid>
                    </Grid>
                </>
                :
                <Grid container>
                    <Helmet>
                        <title>Plan Es(s) Informationen!</title>
                        <meta name="description"
                              content="Hier bekommst du alle wichtigen Informationen zu Plan Es(s) und gesundem Essen."/>
                    </Helmet>


                    <Grid item md={3}/>
                    <Grid item xs={12} md={6} style={{padding:(state.isDesktop ? '' : '0 10px')}}>
                        <h1>Informationen</h1><br/>
                        <Grid container>
                            {state.wikiCategories.map((item) => {
                                return <Grid item md={6} xs={12}>
                                    <li><Link to={'wiki/' + item.urlName} style={{color: '#ffffff'}}>{item.name}</Link>
                                    </li>
                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                    <Grid item md={3}/>
                </Grid>
            }
            <ChatRegister/>
        </>
    );
};

export default Wiki;