import {postData} from "./Post";

export const editRecipe = async (dispatch, recipe) => {
    return postData({action: 'editRecipe', recipe: recipe})
        .then(json => {
            const items = json.data;
            if (json.success) {
                dispatch({
                    type: 'SET_EDIT_REZEPT',
                    rezept: items
                });
                return items;
            } else {
                if (json.error) {
                    dispatch({
                        type: 'SET_ERROR',
                        error: json.error
                    });
                }
                return [];
            }
        })
        .catch(error => {
            console.log(error);
        });
}

