import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import React from "react";
import Wiki from "./ui/Pages/Wiki";
import Title from "./ui/Components/Title/Title";
import Home from "./ui/Pages/Home";
import Wochenplan from "./ui/Pages/Wochenplan";
import Login from "./ui/Pages/Login";
import Store from "./Store/Store";
import Rezept from "./ui/Pages/Rezept";
import Search from "./ui/Pages/Search";
import Agb from "./ui/Pages/Agb";
import ErrorHandler from "./ui/Components/ErrorHandler";
import Footer from "./ui/Components/Footer/Footer";
import Impressum from "./ui/Pages/impressum";
import Datenschutz from "./ui/Pages/datenschutz";
import Register from "./ui/Components/ChatRegister/Register";
import Settings from "./ui/Pages/Settings";
import Einkaufszettel from "./ui/Pages/Einkaufszettel";
import AddRecipe from "./ui/Pages/AddRecipe";
import ScrollToTop from "./ui/Components/ScrollToTop";
import Responsive from "./ui/Components/Responsive";
import ReactPWAInstallProvider from "react-pwa-install";
import LinkAccounts from "./ui/Pages/LinkAccounts";
import Notizen from "./ui/Pages/Notizen";
import ForgotPassword from "./ui/Pages/ForgotPassword";

function App() {
    return (
        <ReactPWAInstallProvider>
            <Router>
                <Store>
                    <Title/>
                    <ErrorHandler/>
                    <div
                        style={{
                            color: '#ffffff',
                            fontSize: '1.2em',
                            minHeight: '100%',
                        }}>
                        <ScrollToTop>
                            <Responsive>
                                <Switch>
                                    <Route path="/wiki/:id" component={Wiki}/>
                                    <Route path="/wiki">
                                        <Wiki/>
                                    </Route>
                                    <Route path="/link/:id" component={LinkAccounts}/>
                                    <Route path="/essensplan" component={Wochenplan}/>
                                    <Route path="/wochenplan">
                                        <Wochenplan/>
                                    </Route>
                                    <Route path="/login">
                                        <Login/>
                                    </Route>
                                    <Route path="/forgot/:email/:id" component={ForgotPassword}/>
                                    <Route path="/forgot">
                                        <ForgotPassword/>
                                    </Route>
                                    <Route path="/register">
                                        <Register/>
                                    </Route>
                                    <Route path="/settings">
                                        <Settings/>
                                    </Route>
                                    <Route path="/rezept-suche">
                                        <Search/>
                                    </Route>
                                    <Route path="/einkaufszettel" component={Einkaufszettel}/>
                                    <Route path="/notizen" component={Notizen}/>
                                    <Route path="/addrecipe/:id" component={AddRecipe}/>
                                    <Route path="/addrecipe" component={AddRecipe}/>
                                    <Route path="/rezept/:id" component={Rezept}/>
                                    <Route path="/agb">
                                        <Agb/>
                                    </Route>
                                    <Route path="/impressum">
                                        <Impressum/>
                                    </Route>
                                    <Route path="/datenschutz">
                                        <Datenschutz/>
                                    </Route>
                                    <Route path="/">
                                        <Home/>
                                    </Route>
                                </Switch>
                            </Responsive>
                        </ScrollToTop>
                    </div>
                    <div style={{height: '70px'}}/>
                    <Footer/>
                </Store>
            </Router>
        </ReactPWAInstallProvider>
    );
}

export default App;




