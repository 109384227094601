const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_ERROR':
            state = {
                ...state,
                error: action.error
            };
            break;

        case 'SET_INFO':
            state = {
                ...state,
                information: action.information
            };
            break;

        case 'SET_WOCHENPLAN':
            state = {
                ...state,
                wochenplan: action.wochenplan
            };
            break;

        case 'SET_USER':
            localStorage.setItem('ssid', action.sessionId);
            state = {
                ...state,
                user: action.user,
                sessionId: action.sessionId
            };
            break;

        case 'SET_SSID':
            state = {
                ...state,
                sessionId: action.sessionId
            };
            break;

        case 'SET_REZEPT':
            let test = {...state.rezept, ...action.rezept}
            state = {
                ...state,
                rezept: test
            };
            break;


        case 'SET_EDIT_REZEPT':
            state = {
                ...state,
                editRezept: action.rezept
            };
            break;

        case 'SET_SETTINGS':
            state = {
                ...state,
                settings: action.settings,
                user: {
                    ...state.user,
                    personenzahl: action.settings.settings.personenzahl,
                    Gluten: action.settings.settings.Gluten,
                    Histamin: action.settings.settings.Histamin,
                    Laktose: action.settings.settings.Laktose,
                    Vegan: action.settings.settings.Vegan,
                    Vegetarisch: action.settings.settings.Vegetarisch,
                }
            };
            break;

        case 'SAVE_SEARCH_RESULT':
            state = {
                ...state,
                search: action.search
            };
            break;

        case 'SET_ZUTATEN':
            state = {
                ...state,
                zutaten: action.zutaten
            };
            break;

        case 'SET_WIKI_ENTRY':
            state = {
                ...state,
                wiki: action.wiki
            };
            break;

        case 'SET_WIKI_CATEGORIES':
            state = {
                ...state,
                wikiCategories: action.wikiCategories
            };
            break;

        case 'SET_EINKAUFSZETTEL':
            state = {
                ...state,
                einkaufszettel: action.einkaufszettel
            };
            break;

        case 'SET_NOTIZEN':
            state = {
                ...state,
                notizen: action.notiz
            };
            break;

        case 'SET_INITIAL':
            let rezepte = {...state.rezept, ...action.rezept}
            state = {
                ...state,
                wochenplan: action.wochenplan,
                rezept: rezepte,
                wiki: action.wiki,
                wikiCategories: action.wikiCategories,
                settings: action.settings,
                zutaten: action.zutaten,
                einkaufszettel: action.einkaufszettel,
                initial: Date.now()
            };
            break;

        case 'SET_IS_DESKTOP':
            state = {
                ...state,
                isDesktop: action.value
            };
            break;

        case 'SET_ACCOUNT_LINKS':
            state = {
                ...state,
                accountLinks: action.value
            };
            break;

        default:
            break;
    }

    localStorage.setItem('state', JSON.stringify(state));

    return state;
};

export default Reducer;