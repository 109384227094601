import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../Store/Store";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from '@material-ui/core/InputAdornment';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import {getEinkaufszettel} from "../../api/getEinkaufszettel";
import {Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";
import {getEinkaufszettelVorschlag} from "../../api/getEinkaufszettelVorschlag";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ModalContainer from "../Components/ModalContainer";

const Einkaufszettel = ({match}) => {
    const [state, dispatch] = useContext(Context);
    const [addToZettel, setAddToZettel] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stopKey, setStopKey] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);


    useEffect(() => {
        getEinkaufszettel(dispatch, {}, state.sessionId);
        // eslint-disable-next-line
    }, []);


    const handleChange = (event) => {
        setAddToZettel(event.target.value);
    };
    const handleAdd = () => {
        getEinkaufszettel(dispatch, {addToZettel: addToZettel}, state.sessionId);
        setAddToZettel('');
        setOptions([]);
    };
    const handleMutation = (ID, action) => {
        getEinkaufszettel(dispatch, {mutate: ID, mutation: action}, state.sessionId);
    };


    const keyPress = (e) => {
        if (!stopKey) {
            if (parseInt(e.keyCode) === 13) {
                // put the login here
                handleAdd()
            }
        }
    };

    const deleteAll = () => {
        setOpenDialog(true);
    };

    useEffect(() => {
        if (addToZettel && addToZettel.length > 1) {
            setLoading(true);
            getEinkaufszettelVorschlag(dispatch, addToZettel).then((result) => {
                setLoading(false);
                setOptions(result);
            })
        }
        // eslint-disable-next-line
    }, [addToZettel])

    return (
        <>
            {localStorage.getItem('ssid') === null &&
            <Redirect to={{
                pathname: "/login",
                state: {referrer: match.url}
            }}/>}

            <Helmet>
                <title>Deine Einkaufsliste - Plan Es(s)</title>
                <meta name="description"
                      content={"Deine Einkaufsliste bei Plan Es(s), erstelle ganz einfach deinen eigenen Essensplan mit deiner eigenen Einkaufsliste."}/>
            </Helmet>

            <Grid container>
                <Grid item md={3}/>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item md={6} xs={8}><h1>Einkaufszettel</h1></Grid>
                        <Grid item md={6} xs={4}>
                            <div style={{textAlign: 'right', marginTop:'35px'}}>
                                <Button
                                    variant="outlined"
                                    onClick={deleteAll}
                                    style={{borderColor: 'rgb(100, 111, 68)', color: '#ffffff'}}
                                >
                                    {(state.isDesktop ? 'Einkaufszettel leeren' : 'Leeren')}
                                </Button></div>
                            <br/></Grid>
                    </Grid>
                    <Autocomplete
                        freeSolo
                        autoComplete
                        onOpen={() => {
                            setStopKey(true);
                        }}
                        onClose={() => {
                            setStopKey(false);
                        }}
                        id="addToEinkaufsliste"
                        options={options}
                        // getOptionLabel={(option) => option}
                        onChange={(event, value) => {
                            setAddToZettel(value);
                        }}
                        value={addToZettel}
                        loading={loading}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Zum Einkaufszettel hinzufügen..."
                                onChange={handleChange}
                                fullWidth
                                value={addToZettel}
                                onKeyDown={keyPress}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                            {params.InputProps.endAdornment}
                                            <IconButton
                                                onClick={handleAdd}
                                            >
                                                <InputAdornment position="end">
                                                    <CreateIcon/>
                                                </InputAdornment>
                                            </IconButton>
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />

                    <br/><br/>

                    {state.einkaufszettel.length > 0 ? state.einkaufszettel.map((item, index) => {
                        return <div key={Math.random()}>
                            <Grid item xs={12}
                                  style={{backgroundColor: (index % 2 === 1 ? 'rgb(139,170,66)' : 'rgba(100,111,68,1)')}}>
                                <Grid container>
                                    <Grid item xs={11}>
                                        <Checkbox onClick={() => handleMutation(item.ID, 'done')}
                                                  checked={Boolean(parseInt(item.erledigt))}
                                                  style={{color: '#00cc00'}}/> <span
                                        style={{textDecoration: (parseInt(item.erledigt) ? 'line-through' : 'none')}}>{item.position}{item.referenz !== null && <>&nbsp;
                                        <small>({item.referenzName})</small></>}</span>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => handleMutation(item.ID, 'delete')}
                                                    style={{padding: '0px'}}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    }) : <>Dein Einkaufszettel ist leer</>}
                </Grid>
                <Grid item md={3}/>
            </Grid>
            <ModalContainer
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                Title={'Möchtest du sicher deinen Einkaufszettel leeren?'}
                actions={<>
                    <Button onClick={() => setOpenDialog(false)}>Abbrechen</Button>
                    <Button onClick={() => {handleMutation(0, 'deleteAll'); setOpenDialog(false);}}>
                        Einkaufszettel leeren
                    </Button>
                </>} >
                Diese Aktion lässt sich nicht rückgängig machen.
            </ModalContainer>
        </>
    );
};

export default Einkaufszettel;