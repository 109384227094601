import React from "react";


const Datenschutz = ({match}) => {

    return (
        <div style={{padding: '40px'}}>
            <b>Datenschutzerklärung</b><br/><br/>

            Soweit nachstehend keine anderen Angaben gemacht werden, ist die Bereitstellung Ihrer personenbezogenen
            Daten weder gesetzlich oder vertraglich vorgeschrieben, noch für einen Vertragsabschluss erforderlich. Sie
            sind zur Bereitstellung der Daten nicht verpflichtet. Eine Nichtbereitstellung hat keine Folgen. Dies gilt
            nur soweit bei den nachfolgenden Verarbeitungsvorgängen keine anderweitige Angabe gemacht wird.
            "Personenbezogene Daten" sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
            natürliche Person beziehen.
            <br/><br/>
            <b>Server-Logfiles</b><br/>
            Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Es werden bei jedem Zugriff
            auf unsere Website Nutzungsdaten durch Ihren Internetbrowser übermittelt und in Protokolldaten
            (Server-Logfiles) gespeichert. Zu diesen gespeicherten Daten gehören z.B. Name der aufgerufenen Seite, Datum
            und Uhrzeit des Abrufs, übertragene Datenmenge und der anfragende Provider. Diese Daten dienen
            ausschlie&szlig;lich der Gewährleistung eines störungsfreien Betriebs unserer Website und zur Verbesserung
            unseres Angebotes. Eine Zuordnung dieser Daten zu einer bestimmten Person ist nicht möglich.
            <br/><br/>
            <b>Erhebung und Verarbeitung bei Nutzung des Kontaktformulars </b><br/>
            Bei der Nutzung des Kontaktformulars erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse,
            Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung dient dem Zweck
            der Kontaktaufnahme. Mit Absenden Ihrer Nachricht willigen Sie in die Verarbeitung der übermittelten Daten
            ein. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung.
            Sie können Ihre Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmä&szlig;igkeit der
            aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihre E-Mail-Adresse nutzen
            wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschlie&szlig;end gelöscht, sofern Sie der
            weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.
            <br/><br/>
            <b>Kundenkonto</b><br/>
            Bei der Eröffnung eines Kundenkontos erheben wir Ihre personenbezogenen Daten in dem dort angegeben Umfang.
            Die Datenverarbeitung dient dem Zweck, Ihr Einkaufserlebnis zu verbessern und die Bestellabwicklung zu
            vereinfachen. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung. Sie
            können Ihre Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmä&szlig;igkeit der
            aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihr Kundenkonto wird
            anschlie&szlig;end gelöscht.
            <br/><br/>
            <b>Erhebung, Verarbeitung und Nutzung personenbezogener Daten bei Bestellungen </b><br/>
            Bei der Bestellung erheben und verwenden wir Ihre personenbezogenen Daten nur, soweit dies zur Erfüllung und
            Abwicklung Ihrer Bestellung sowie zur Bearbeitung Ihrer Anfragen erforderlich ist. Die Bereitstellung der
            Daten ist für den Vertragsschluss erforderlich. Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag
            geschlossen werden kann. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. b DSGVO und ist für die
            Erfüllung eines Vertrags mit Ihnen erforderlich. Eine Weitergabe Ihrer Daten an Dritte ohne Ihre
            ausdrückliche Einwilligung erfolgt nicht. Ausgenommen hiervon sind lediglich unsere Dienstleistungspartner,
            die wir zur Abwicklung des Vertragsverhältnisses benötigen oder Dienstleister derer wir uns im Rahmen einer
            Auftragsverarbeitung bedienen. Neben den in den jeweiligen Klauseln dieser Datenschutzerklärung benannten
            Empfängern sind dies beispielsweise Empfänger folgender Kategorien: Versanddienstleister,
            Zahlungsdienstleister, Warenwirtschaftsdienstleister, Diensteanbieter für die Bestellabwicklung, Webhoster,
            IT-Dienstleister und Dropshipping Händler. In allen Fällen beachten wir strikt die gesetzlichen Vorgaben.
            Der Umfang der Datenübermittlung beschränkt sich auf ein Mindestma&szlig;.
            <br/><br/>
            <b>Verwendung der E-Mail-Adresse für die Zusendung von Newslettern</b><br/>
            Wir nutzen Ihre E-Mail-Adresse unabhängig von der Vertragsabwicklung ausschlie&szlig;lich für eigene Werbezwecke
            zum Newsletterversand, sofern Sie dem ausdrücklich zugestimmt haben. Die Verarbeitung erfolgt auf Grundlage
            des Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung. Sie können die Einwilligung jederzeit widerrufen, ohne
            dass die Rechtmä&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.
            Sie können dazu den Newsletter jederzeit unter Nutzung des entsprechenden Links im Newsletter oder durch
            Mitteilung an uns abbestellen. Ihre E-Mail-Adresse wird danach aus dem Verteiler entfernt.
            <br/><br/>
            Ihre Daten werden dabei an einen Dienstleister für E-Mail-Marketing im Rahmen einer Auftragsverarbeitung
            weitergegeben. Eine Weitergabe an sonstige Dritte erfolgt nicht.
            <br/><br/>
            <b>Verwendung von PayPal</b><br/>
            Alle PayPal-Transaktionen unterliegen der PayPal-Datenschutzerklärung. Diese finden Sie unter
            https://www.paypal.com/de/webapps/mpp/ua/privacy-prev?locale.x=de_DE
            <br/><br/>
            <b>Cookies </b><br/>
            Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die im Internetbrowser bzw. vom
            Internetbrowser auf dem Computersystem eines Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf,
            so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine
            charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der
            Website ermöglicht. Wir setzen Cookies zu dem Zweck ein, unser Angebot nutzerfreundlicher, effektiver und
            sicherer zu machen. Des Weiteren ermöglichen Cookies unseren Systemen, Ihren Browser auch nach einem
            Seitenwechsel zu erkennen und Ihnen Services anzubieten. Einige Funktionen unserer Internetseite können ohne
            den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach
            einem Seitenwechsel wiedererkannt wird.
            <br/><br/>
            Die Verarbeitung erfolgt auf Grundlage des § 15 (3) TMG sowie Art. 6 (1) lit. f DSGVO aus dem berechtigten
            Interesse an den oben genannten Zwecken.
            Die auf diese Weise von Ihnen erhobenen Daten werden durch technische Vorkehrungen pseudonymisiert. Eine
            Zuordnung der Daten zu Ihrer Person ist daher nicht mehr möglich. Die Daten werden nicht gemeinsam mit
            sonstigen personenbezogenen Daten von Ihnen gespeichert.
            Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art.
            6 (1) f DSGVO beruhenden Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.
            Cookies werden auf Ihrem Rechner gespeichert. Daher haben Sie die volle Kontrolle über die Verwendung von
            Cookies. Durch die Auswahl entsprechender technischer Einstellungen in Ihrem Internetbrowser können Sie die
            Speicherung der Cookies und &Uuml;bermittlung der enthaltenen Daten verhindern. Bereits gespeicherte Cookies
            können jederzeit gelöscht werden. Wir weisen Sie jedoch darauf hin, dass Sie dann gegebenenfalls nicht
            sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
            Unter den nachstehenden Links können Sie sich informieren, wie Sie die Cookies bei den wichtigsten Browsern
            verwalten (u.a. auch deaktivieren) können:
            Chrome Browser: https://support.google.com/accounts/answer/61416?hl=de
            Internet Explorer:
            https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
            Mozilla Firefox: https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
            Safari: https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac
            <br/><br/>
            <b>Verwendung von YouTube</b><br/>
            Wir verwenden auf unserer Website die Funktion zur Einbettung von YouTube-Videos der YouTube LLC. (901
            Cherry Ave., San Bruno, CA 94066, USA; "YouTube").
            YouTube ist ein mit der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google")
            verbundenes Unternehmen.
            Die Funktion zeigt bei YouTube hinterlegte Videos in einem iFrame auf der Website an. Dabei ist die Option
            "Erweiterter Datenschutzmodus" aktiviert. Dadurch werden von YouTube keine Informationen über die
            Besucher der Website gespeichert. Erst wenn Sie sich ein Video ansehen, werden Informationen darüber an
            YouTube übermittelt und dort gespeichert.
            Nähere Informationen zur Erhebung und Nutzung der Daten durch YouTube und Google, über Ihre diesbezüglichen
            Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den Datenschutzhinweisen von YouTube
            (https://www.youtube.com/t/privacy).
            <br/><br/>
            <b>Dauer der Speicherung</b><br/>
            Nach vollständiger Vertragsabwicklung werden die Daten zunächst für die Dauer der Gewährleistungsfrist,
            danach unter Berücksichtigung gesetzlicher, insbesondere steuer- und handelsrechtlicher Aufbewahrungsfristen
            gespeichert und dann nach Fristablauf gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht
            zugestimmt haben.
            <br/><br/>
            <b>Rechte der betroffenen Person</b><br/>
            Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte nach Art. 15 bis 20 DSGVO zu:
            Recht auf Auskunft, auf Berichtigung, auf Löschung, auf Einschränkung der Verarbeitung, auf
            Datenübertragbarkeit.
            Au&szlig;erdem steht Ihnen nach Art. 21 (1) DSGVO ein Widerspruchsrecht gegen die Verarbeitungen zu, die auf Art.
            6 (1) f DSGVO beruhen, sowie gegen die Verarbeitung zum Zwecke von Direktwerbung.
            <br/><br/>
            Kontaktieren Sie uns auf Wunsch. Die Kontaktdaten finden Sie in unserem Impressum.
            <br/><br/>
            <b>Beschwerderecht bei der Aufsichtsbehörde</b><br/>
            Sie haben gemä&szlig; Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht
            sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmä&szlig;ig erfolgt.
            <br/><br/>

            <br/>
                <p><strong>Statistische Auswertungen</strong><br/>
                        <h3>Matomo</h3>
                        <p>Diese Website benutzt den Open Source Webanalysedienst Matomo. Matomo verwendet Technologien,
                                die die seiten&uuml;bergreifende
                                Wiedererkennung des Nutzers zur Analyse des Nutzerverhaltens erm&ouml;glichen
                                (z.&nbsp;B.
                                Device-Fingerprinting). Die durch Matomo erfassten Informationen &uuml;ber die Benutzung
                                dieser Website
                                werden auf unserem Server gespeichert. Die IP-Adresse wird vor der Speicherung
                                anonymisiert.</p>
                        <p>Mit Hilfe von Matomo sind wir in der Lage Daten &uuml;ber die Nutzung unserer Website durch
                                die
                                Websitebesucher zu erfassen und zu analysieren. Hierdurch k&ouml;nnen wir u.&nbsp;a.
                                herausfinden, wann
                                welche Seitenaufrufe get&auml;tigt wurden und aus welcher Region sie kommen.
                                Au&szlig;erdem erfassen wir
                                verschiedene Logdateien (z.&nbsp;B. IP-Adresse, Referrer, verwendete Browser und
                                Betriebssysteme) und k&ouml;nnen
                                messen, ob unsere Websitebesucher bestimmte Aktionen durchf&uuml;hren (z.&nbsp;B.
                                Klicks, K&auml;ufe u.
                                &Auml;.).</p>
                        <p>Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                                Websitebetreiber
                                hat ein berechtigtes Interesse an der anonymisierten Analyse des Nutzerverhaltens, um
                                sowohl sein Webangebot
                                als auch seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung abgefragt
                                wurde, erfolgt die
                                Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO
                                und &sect; 25 Abs. 1 TTDSG,
                                soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen
                                im Endger&auml;t des
                                Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
                                ist jederzeit
                                widerrufbar.</p>
                        <h4>IP-Anonymisierung</h4>
                        <p>Bei der Analyse mit Matomo setzen wir IP-Anonymisierung ein. Hierbei wird Ihre IP-Adresse vor
                                der Analyse gek&uuml;rzt,
                                sodass Sie Ihnen nicht mehr eindeutig zuordenbar ist.</p>
                        <h4>Hosting</h4>
                        <p>Wir hosten Matomo ausschlie&szlig;lich auf unseren eigenen Servern, sodass alle Analysedaten
                                bei uns
                                verbleiben und nicht weitergegeben werden.</p>
                        <h4>Opt-out möglichkeit:</h4>
                        <iframe
                            title={'matomo'}
                            style={{border: '0px',  width: '100%'}}
                            src="https://engert.digital/analytics/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=ffffff&fontSize=1em&fontFamily=sans-serif"
                        />





                letzte Aktualisierung: 22.01.2022

                <br/><br/>
            </p>

        </div>
    );
};

export default Datenschutz;