import React, {useContext, useEffect, useState} from "react";
import BanderoleSmall from "../../../assets/images/banderole-small.jpg";
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";
import MediaQuery from 'react-responsive'
import {makeStyles} from "@material-ui/core/styles";
import {Context} from "../../../Store/Store";
import { useReactPWAInstall } from "react-pwa-install";
import myLogo from '../../../android-icon-72x72.png'
import {getInitial} from "../../../api/getInitial";

const useStyles = makeStyles((theme) => ({
    link: {
        color: '#ffffff',
        textDecoration: 'none',
        margin: '0 10px',
    }
}));

const Title = () => {
    const [state, dispatch] = useContext(Context);
    const classes = useStyles();
    const [showMobile, setShowmobile] = useState(false);
    const [getInitialState, setGetInitialState] = useState(false);
    const openMenu = () => {
        setShowmobile(!showMobile);
    };
    const GreenColor = '#646f44';
    const logout = () => {
        dispatch({
            type: 'SET_USER',
            user: null,
            sessionId: ''
        });
        setShowmobile(!showMobile);
        localStorage.removeItem('ssid');
    }
    let showMobileCss = showMobile ? {display: 'block'} : {display: 'none'};
    const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

    // eslint-disable-next-line
    useEffect(()=>{
        if ((!state.initial || (state.initial < (Date.now() - 86400))) && !getInitialState) {
            getInitial(dispatch);
            setGetInitialState(true)
        }
        // eslint-disable-next-line
    });

    const handleClick = () => {
        openMenu();
        pwaInstall({
            title: "Plan Es(s) installieren",
            logo: myLogo,
            features: (
                <ul>
                    <li>Einfache Wochenpläne</li>
                    <li>Einkaufsliste</li>
                    <li>Rezeptsuche</li>
                    <li>Immer griff bereit</li>
                </ul>
            ),
            description: 'Plan Es(s) hilft dir bei der täglichen Frage: "Was soll ich nur essen?"',
        })
            .then(() =>  dispatch({
                type: 'SET_INFO',
                information: "Vielen Dank fürs installieren von Plan Es(s)"
            }))
            .catch(() =>  dispatch({
                type: 'SET_ERROR',
                information: "Beim installieren ist ein Fehler aufgetreten"
            }));
    };

    return (
        <>
            <img src={BanderoleSmall} alt={'Planess Banderole'} width={'100%'}/>
            <Grid container style={{backgroundColor: GreenColor, paddingTop: '0px', marginTop: '-4px', height: '70px'}}>
                <Grid item xs={1}>&nbsp;</Grid>
                <Grid item xs={1} style={{marginTop: '23px'}}>
                    <Link to={'/'} style={{color: '#ffffff', fontWeight: 700, fontSize: 18, textDecoration: 'none'}}>
                        {/*<img src={Logo} alt={'Planess Logo'} width={'100px'} style={{marginTop: '10px'}}/>*/}
                        PLAN&nbsp;ES(S)
                    </Link>
                </Grid>
                <Grid item xs={8}>
                    <MediaQuery minWidth={901}>
                        <Grid container style={{marginTop: '25px', color: '#ffffff', fontWeight: 600}}>
                            <Grid item xs={8} style={{display: 'flex', marginLeft:'150px'}}>
                                <Link to={'/wiki'}
                                      className={classes.link}>INFORMATIONEN</Link>
                                <Link to={'/essensplan'}
                                      className={classes.link}>ESSENSPLAN</Link>
                                <Link to={'/rezept-suche'}
                                      className={classes.link}>REZEPT&nbsp;SUCHE</Link>
                                <Link to={'/einkaufszettel'}
                                      className={classes.link}>EINKAUFSZETTEL</Link>
                                <Link to={'/notizen'}
                                      className={classes.link}>NOTIZEN</Link>
                                {localStorage.getItem('ssid') === null && <Link to={'/login'} className={classes.link}>LOGIN</Link>}
                                {localStorage.getItem('ssid') !== null  && <>
                                    <Link to={'/settings'} className={classes.link}>EINSTELLUNGEN</Link>
                                    <Link to={'/'} className={classes.link} onClick={logout}>LOGOUT</Link>
                                </>}
                            </Grid>
                        </Grid>
                    </MediaQuery>
                </Grid>
                <MediaQuery maxWidth={900}>
                    <Grid item xs={1}>
                        <Button style={{marginTop: '15px', color: '#ffffff'}} onClick={openMenu}>
                            <MenuIcon/>
                        </Button>
                    </Grid>
                    <Grid item xs={12} style={{...showMobileCss, backgroundColor: GreenColor, zIndex: 999}}
                          id={'mobileMenu'}>
                        <Grid item xs={8} style={{display: ''}}>
                            <Link to={'/wiki'} onClick={openMenu}
                                  className={classes.link}>INFORMATIONEN</Link><br/><br/>
                            <Link to={'/essensplan'} onClick={openMenu}
                                  className={classes.link}>ESSENSPLAN</Link><br/><br/>
                            <Link to={'/rezept-suche'} onClick={openMenu}
                                  className={classes.link}>REZEPT&nbsp;SUCHE</Link><br/><br/>
                            <Link to={'/einkaufszettel'} onClick={openMenu}
                                  className={classes.link}>EINKAUFSZETTEL</Link><br/><br/>
                            <Link to={'/notizen'} onClick={openMenu}
                                  className={classes.link}>NOTIZEN</Link><br/><br/>
                            {localStorage.getItem('ssid') === null &&
                            <>
                                <Link to={'/login'} onClick={openMenu}
                                      className={classes.link}>LOGIN</Link><br/><br/>
                                <Link to={'/register'} onClick={openMenu}
                                      className={classes.link}>KOSTENLOS REGISTRIEREN</Link><br/><br/></>
                            }
                            {localStorage.getItem('ssid') !== null &&
                            <>
                                <Link to={'/settings'} className={classes.link} onClick={openMenu}>EINSTELLUNGEN</Link><br/><br/>
                                <Link to={'/'} className={classes.link} onClick={logout}>LOGOUT</Link><br/><br/>
                            </>
                            }
                            {supported() && !isInstalled() && (
                                <Link to={'/essensplan'} className={classes.link} onClick={handleClick }>
                                    INSTALLIEREN
                                </Link>
                            )}
                            <Link to={'/agb'}
                                  className={classes.link}>AGB</Link><br/><br/>
                            <Link to={'/datenschutz'}
                                  className={classes.link}>DATENSCHUTZ</Link><br/><br/>
                            <Link to={'/impressum'}
                                  className={classes.link}>IMPRESSUM</Link><br/><br/>

                        </Grid>
                    </Grid>
                </MediaQuery>
            </Grid>
        </>
    );
};

export default Title;