import React, {useContext} from "react";
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {Context} from "../../../Store/Store";

const useStyles = makeStyles((theme) => ({
    link: {
        color: '#ffffff',
        textDecoration: 'none',
        margin: '0 10px',
    }
}));

const Footer = () => {
    const classes = useStyles();
    const [state] = useContext(Context);

    return (
        <>
            <Grid container style={{
                position: 'fixed',
                backgroundColor: '#646f44',
                bottom: '0px',
                marginTop: '100px',
                height: '70px'
            }}>
                <Grid item xs={12}>
                    <Grid container style={{marginTop: '25px', color: '#ffffff', fontWeight: 600}}>
                        <Grid item md={2}/>
                        <Grid item md={8} style={{display: 'flex'}}>
                            {state.isDesktop ? <>
                                    <Link to={'/agb'}
                                          className={classes.link}>AGB</Link>
                                    <Link to={'/datenschutz'}
                                          className={classes.link}>Datenschutz</Link>
                                    <Link to={'/impressum'}
                                          className={classes.link}>Impressum</Link>
                                </>
                                :
                                <>

                                    <Link to={'/essensplan'}
                                          className={classes.link}>Essensplan</Link>
                                    <Link to={'/einkaufszettel'}
                                          className={classes.link}>Einkaufszettel</Link>
                                    <Link to={'/essensplan'} className={classes.link}>
                                        Installieren
                                    </Link>
                                </>
                            }

                        </Grid>
                        <Grid item md={2}/>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Footer;