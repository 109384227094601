import {postData} from "./Post";

export const getInitial = async (dispatch) => {
    return postData({action: 'initial'})
        .then(json => {
            const items = json;

            if (json.success) {
                dispatch({
                    type: 'SET_INITIAL',
                    wochenplan: json.data.wochenplan,
                    rezept: json.data.rezepte,
                    wiki: json.data.wiki.wikiEntries,
                    wikiCategories: json.data.wiki.wikiUrls,
                    settings: json.data.settings,
                    zutaten: json.data.zutaten,
                    einkaufszettel: json.data.einkaufszettel
                });
                return items;
            } else {
                if (json.error) {
                    dispatch({
                        type: 'SET_ERROR',
                        error: json.error
                    });
                }
                return [];
            }

        })
        .catch(error => {
            console.log(error);
        });
}

