import React from "react";


const Impressum = ({match}) => {

    return (
        <div style={{padding: '40px'}}>
            <br/><br/>

            <span style={{fontSize: '14px'}}><strong><span style={{fontSize: '16px'}}>Impressum</span></strong></span><br/>
            <br/>
            <span style={{fontSize: '14px'}}><strong><span style={{fontSize: '16px'}}><span style={{fontSize: '12px'}}>Gesetzliche Anbieterkennung:</span></span></strong></span><br/>
            <br/>
            <span style={{fontSize: '14px'}}>
                <strong>Tobias Engert (Einzelunternehmer)</strong><br/>
                Zu den Steinbr&uuml;chen 12b<br/>
                64853 Otzberg<br/>
                Deutschland<br/>
            </span>
            <strong><span style={{fontSize: '14px'}}>Telefon: 0170 46 37 971</span></strong><br/>
            <br/>
            <strong><span style={{fontSize: '14px'}}>E-Mail: </span></strong><strong><span
            style={{fontSize: '14px'}}>info@plan-ess.de</span></strong><strong>
        </strong>
            <br/>
            <br/>
            <p><strong>Rechtliche Hinweise zur Webseite</strong><br/>
                <br/>
                Alle Texte, Bilder und weiter hier ver&ouml;ffentlichten Informationen unterliegen dem Urheberrecht des
                Anbieters, soweit nicht Urheberrechte Dritter bestehen. In jedem Fall ist eine Vervielf&auml;ltigung,
                Verbreitung oder &ouml;ffentliche Wiedergabe ausschlie&szlig;lich im Falle einer widerruflichen und
                nicht &uuml;bertragbaren Zustimmung des Anbieters gestattet.<br/>
                &nbsp;<br/>
                F&uuml;r alle mittels Querverweis (Link) verbundenen Webinhalte &uuml;bernimmt der Anbieter keine
                Verantwortung, da es
                sich hierbei nicht um eigene Inhalte handelt. Die verlinkten Seiten wurden auf rechtswidrige
                Inhalte &uuml;berpr&uuml;ft, zum Zeitpunkt der
                Verlinkung waren solche nicht erkennbar. Verantwortlich f&uuml;r den Inhalt der verlinkten Seiten ist
                deren Betreiber. Der Anbieter hat hierzu keine allgemeine &Uuml;berwachungs-
                und Pr&uuml;fungspflicht. Bei Bekanntwerden einer Rechtsverletzung wird der entsprechende Link jedoch
                umgehend entfernt.<br/>
                <br/><br/>


                <h2>Disclaimer - rechtliche Hinweise</h2>
                &sect; 1 Haftungsbeschr&auml;nkung<br/> Die Inhalte dieser
                Website werden mit gr&ouml;&szlig;tm&ouml;glicher Sorgfalt
                erstellt. Der Anbieter &uuml;bernimmt jedoch keine Gew&auml;hr
                f&uuml;r die Richtigkeit, Vollst&auml;ndigkeit und Aktualit&auml;t
                der bereitgestellten Inhalte. Die Nutzung der Inhalte der Website
                erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete
                Beitr&auml;ge geben die Meinung des jeweiligen Autors und nicht
                immer die Meinung des Anbieters wieder. Mit der reinen Nutzung der
                Website des Anbieters kommt keinerlei Vertragsverh&auml;ltnis
                zwischen dem Nutzer und dem Anbieter zustande. <br/>
                <br/> &sect; 2 Externe Links<br/> Diese Website enth&auml;lt
                Verkn&uuml;pfungen zu Websites Dritter ("externe Links"). Diese
                Websites unterliegen der Haftung der jeweiligen Betreiber. Der
                Anbieter hat bei der erstmaligen Verkn&uuml;pfung der externen
                Links die fremden Inhalte daraufhin &uuml;berpr&uuml;ft, ob
                etwaige Rechtsverst&ouml;&szlig;e bestehen. Zu dem Zeitpunkt waren
                keine Rechtsverst&ouml;&szlig;e ersichtlich. Der Anbieter hat
                keinerlei Einfluss auf die aktuelle und zuk&uuml;nftige Gestaltung
                und auf die Inhalte der verkn&uuml;pften Seiten. Das Setzen von
                externen Links bedeutet nicht, dass sich der Anbieter die hinter
                dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine
                st&auml;ndige Kontrolle der externen Links ist f&uuml;r den
                Anbieter ohne konkrete Hinweise auf Rechtsverst&ouml;&szlig;e
                nicht zumutbar. Bei Kenntnis von Rechtsverst&ouml;&szlig;en werden
                jedoch derartige externe Links unverz&uuml;glich gel&ouml;scht. <br/>
                <br/> &sect; 3 Urheber- und Leistungsschutzrechte<br/> Die auf
                dieser Website ver&ouml;ffentlichten Inhalte unterliegen dem
                deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen
                Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung
                bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder
                jeweiligen Rechteinhabers. Dies gilt insbesondere f&uuml;r
                Vervielf&auml;ltigung, Bearbeitung, Ãœbersetzung, Einspeicherung,
                Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder
                anderen elektronischen Medien und Systemen. Inhalte und Rechte
                Dritter sind dabei als solche gekennzeichnet. Die unerlaubte
                Vervielf&auml;ltigung oder Weitergabe einzelner Inhalte oder
                kompletter Seiten ist nicht gestattet und strafbar. Lediglich die
                Herstellung von Kopien und Downloads f&uuml;r den
                pers&ouml;nlichen, privaten und nicht kommerziellen Gebrauch ist
                erlaubt. <br/>
                <br/> Die Darstellung dieser Website in fremden Frames ist nur
                mit schriftlicher Erlaubnis zul&auml;ssig. <br/>
                <br/> &sect; 4 Besondere Nutzungsbedingungen<br/> Soweit
                besondere Bedingungen f&uuml;r einzelne Nutzungen dieser Website
                von den vorgenannten Paragraphen abweichen, wird an entsprechender
                Stelle ausdr&uuml;cklich darauf hingewiesen. In diesem Falle
                gelten im jeweiligen Einzelfall die besonderen
                Nutzungsbedingungen.<br/><br/>
                <br/>
                <div>
                    <p>Die Europ&auml;ische Kommission stellt k&uuml;nftig eine Plattform zur Online-Streitbeilegung
                        (OS) bereit. Die Plattform finden Sie unter <a href="http://ec.europa.eu/consumers/odr/"
                                                                       rel="noreferrer" target="_blank">http://ec.europa.eu/consumers/odr/</a><br/>
                    </p>
                </div>
            </p>
            <br/>
        </div>


    );
};

export default Impressum;