import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import lacto from '../../assets/images/home/FP_lacto.jpg';
import vegan from '../../assets/images/home/FP_vegan.jpg';
import vegetarisch from '../../assets/images/home/FP_vegetarisch.jpg';
import gluten from '../../assets/images/home/glutenfrei.jpg';
import flexitarisch from '../../assets/images/home/FP-ramen.jpg';
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";


const Home = () => {
    const [flexitarischOpa, setFlexitarischOpa] = useState(1);
    const [vegetarischOpa, setVegetarischOpa] = useState(0.5);
    const [veganOpa, setVeganOpa] = useState(0.5);
    const [glutenOpa, setGlutenOpa] = useState(0.5);
    const [lactoOpa, setLactoOpa] = useState(0.5);

    const opacityFunc = (id) => {
        switch (id) {
            case 'flexitarisch':
                setVeganOpa(0.5);
                setGlutenOpa(0.5);
                setLactoOpa(0.5);
                setVegetarischOpa(0.5);
                setFlexitarischOpa(1);
                break;
            case 'vegetarisch':
                setVeganOpa(0.5);
                setGlutenOpa(0.5);
                setLactoOpa(0.5);
                setFlexitarischOpa(0.5);
                setVegetarischOpa(1);
                break;
            case 'vegan':
                setVegetarischOpa(0.5);
                setGlutenOpa(0.5);
                setLactoOpa(0.5);
                setFlexitarischOpa(0.5);
                setVeganOpa(1);
                break;
            case 'gluten':
                setVegetarischOpa(0.5);
                setVeganOpa(0.5);
                setLactoOpa(0.5);
                setFlexitarischOpa(0.5);
                setGlutenOpa(1);
                break;
            case 'lacto':
                setVegetarischOpa(0.5);
                setVeganOpa(0.5);
                setGlutenOpa(0.5);
                setFlexitarischOpa(0.5);
                setLactoOpa(1);
                break;
            default:
                setVeganOpa(0.5);
                setGlutenOpa(0.5);
                setLactoOpa(0.5);
                setVegetarischOpa(0.5);
                setFlexitarischOpa(1);
                break;
        }
    };

    return (
        <>
            <Helmet>
                <title>Plan Es(s) - Dein Essensplaner</title>
                <meta name="description" content={"Plan Es(s) - Dein kostenloser, einfacher Essensplan, plane jetzt!"} />
            </Helmet>
            <Grid container
                  style={{
                      // background: "linear-gradient(to bottom, rgba(172,203,69,1) 1%,rgba(100,111,68,1) 100%)",
                      color: '#ffffff',
                      fontSize: '1em'
                  }}>
                <Grid item md={3}/>
                <Grid item xs={12} md={6} style={{textAlign: 'left', padding:'10px'}}>
                    <h1 style={{fontSize:'1.8rem'}}>Plan Es(s) - Dein Essensplan schnell und einfach geplant!</h1>
                    Hier kannst du deinen <Link to={'/essensplan'} style={{color:'#ffffff'}}>Essensplan</Link> unkompliziert und flexibel für die nächste Woche erstellen. <br/><br/>

                    Mit deinem eigenen Account kannst du dir <Link to={'/essensplan'} style={{color:'#ffffff'}}>Rezeptinspirationen</Link> anschauen, deinen <Link to={'/einkaufszettel'} style={{color:'#ffffff'}}>Einkaufszettel</Link> planen
                    und Lebensmittel, die du nicht magst oder verträgst, ganz einfach ausschließen.<br/><br/>

                    Wenn du Zutaten von deinen letzten Kocherfolgen übrig hast, kannst du mit Hilfe der <Link to={'/rezept-suche'} style={{color:'#ffffff'}}>Rezeptsuche</Link> schnell
                    ein passendes Rezept finden, um so nachhaltig wie möglich zu sein. <br/><br/>

                    Du hast Interesse daran mehr über Ernährung, Zutaten oder Tipps und Ticks zu erfahren? <br/>Dann kannst du
                    unter <Link to={'/wiki'} style={{color:'#ffffff'}}>Informationen</Link> deinen Wissensdurst stillen.
                    <br/><br/>
                    Wenn du deinen Essensplan weiter individualisieren möchtest kannst du dich komplett <Link to={'/register'} style={{color:'#ffffff'}}>kostenlos und unverbindlich registrieren</Link>.
                    <br/><br/><br/>

                </Grid>
                <Grid item md={3}/>
                <Grid item md={3}/>
                <Grid item xs={12} md={2} style={{textAlign: 'right', padding:'10px', minHeight:'550px'}}>
                    <Button id={'flexitarisch'} style={{
                        backgroundImage: `url(${flexitarisch})`,
                        color: '#ffffff',
                        maxHeight: '110px',
                        height:'100%',
                        width: '100%',
                        backgroundRepeat: "no-repeat",
                        fontSize: '20px',
                        fontWeight: '600',
                        opacity: flexitarischOpa
                    }} onMouseOver={() => {
                        opacityFunc('flexitarisch');
                    }}>Flexitarisch</Button><br/>

                    <Button id={'vegetarisch'} style={{
                        backgroundImage: `url(${vegetarisch})`,
                        color: '#ffffff',
                        maxHeight: '110px',
                        height:'100%',
                        width: '100%',
                        backgroundRepeat: "no-repeat",
                        fontSize: '20px',
                        fontWeight: '600',
                        opacity: vegetarischOpa
                    }} onMouseOver={() => {
                        opacityFunc('vegetarisch');
                    }}>Vegetarisch</Button><br/>

                    <Button id={'vegan'} style={{
                        backgroundImage: `url(${vegan})`,
                        color: '#ffffff',
                        maxHeight: '110px',
                        height:'100%',
                        width: '100%',
                        backgroundRepeat: "no-repeat",
                        fontSize: '20px',
                        fontWeight: '600',
                        opacity: veganOpa
                    }} onMouseOver={() => {
                        opacityFunc('vegan');
                    }}>Vegan</Button><br/>

                    <Button id={'gluten'} style={{
                        backgroundImage: `url(${gluten})`,
                        color: '#ffffff',
                        maxHeight: '110px',
                        height:'100%',
                        width: '100%',
                        backgroundRepeat: "no-repeat",
                        fontSize: '20px',
                        fontWeight: '600',
                        opacity: glutenOpa
                    }} onMouseOver={() => {
                        opacityFunc('gluten');
                    }}>Glutenfrei</Button><br/>
                    <Button id={'lacto'} style={{
                        backgroundImage: `url(${lacto})`,
                        color: '#ffffff',
                        maxHeight: '110px',
                        height:'100%',
                        width: '100%',
                        backgroundRepeat: "no-repeat",
                        fontSize: '20px',
                        fontWeight: '600',
                        opacity: lactoOpa
                    }} onMouseOver={() => {
                        opacityFunc('lacto');
                    }}
                    >Laktosefrei</Button><br/>
                </Grid>
                <Grid item xs={12} md={4} style={{padding: '25px', minHeight:'600px'}}>
                    <div style={{display: (flexitarischOpa === 1 ? 'block' : 'none'), textAlign: 'left'}}>
                        <br/>
                        Die flexitarische Küche ist uneingeschränkt; sowohl Fleisch als auch Fisch stehen mit auf dem Speiseplan.<br/><br/>
                        Trotzdem wird darauf geachtet wenig Fleisch oder nur ausgewählte Fleischsorten zu essen,
                        dies fördert die Vielfälltigkeit der Esskultur.<br/><br/>
                        Der Essensplan beinhaltet daher eine große Auswahl an Obst und Gemüse.<br/>

                        <br/>
                        <br/>
                    </div>
                    <div style={{display: (vegetarischOpa === 1 ? 'block' : 'none'), textAlign: 'left'}}>
                        <br/>
                        Die vegetarische Küche verzichtet auf alle Produkte von toten Tieren; hier gibt es noch weitere
                        Abstufungen.<br/>
                        <ol>
                            <li>Der verbreiteste Typ von Vegetariern ist der Ovo-Lacto-Vegetarier. Hier werden neben
                                pflanzlichen Zutaten auch Milch und Eier konsumiert.
                            </li>
                            <li>Weiter eingeschränkt sind die Lacto-Vegetarier. Diese konsumieren neben pflanzlichen
                                Zutaten nur aus Milch gewonnene Produkte.
                            </li>
                            <li>Außerdem gibt es die Ovo-Vegetarier. Zusätzlich zu pflanzlichen Zutaten werden hier
                                noch Eier gegessen.
                            </li>
                            <li>Anders als man nun denken würde wird der reine Vegetarier nicht Veganer genannt, denn dieser
                                isst noch Produkte wie zum Beispiel Honig.
                            </li>
                            <li>Im weiteren Sinne gibt es noch eine Sonderform: Die Pescetarier. Diese essen neben
                                pflanzlichen Zutaten auch Fisch. Auch hier könnte man sicher noch zwischen Ovo und Lacto
                                Pescitariern unterscheiden.
                            </li>
                        </ol>
                        <br/>
                        <br/>
                    </div>
                    <div style={{display: (veganOpa === 1 ? 'block' : 'none'), textAlign: 'left'}}>
                        <br/>
                        Veganer essen ausschließlich pflanzliche Produkte. Diese stammen weder vom lebenden, noch vom
                        toten Tier.<br/><br/>
                        Anders als viele glauben lässt sich auch Vegan ausgewogen und abwechslungsreich
                        kochen.<br/><br/>
                        Mit Hilfe eines Essensplans wird verhindert zu oft das gleiche zu essen. Auch kann man
                        mit spannenden Rezepten neue Geschmäcker probieren.
                        <br/>
                        <br/>
                    </div>
                    <div style={{display: (glutenOpa === 1 ? 'block' : 'none'), textAlign: 'left'}}>
                        <br/>
                        Glutenfrei ist keine Ernährungsform, jedoch gibt es auch hier vieles zu beachten. Gerade wenn
                        man unter Zöliakie leidet, ist es wichtig sich mit der eigenen Ernährung zu
                        beschäftigen.<br/><br/>
                        Ein Essensplan, der dies berücksichtigt, kann dann umso wertvoller sein.
                        <br/>
                        <br/>
                    </div>

                    <div style={{display: (lactoOpa === 1 ? 'block' : 'none'), textAlign: 'left'}}>
                        <br/>
                        Viele Menschen sind Laktoseintollerant und wissen es nicht einmal. Laktose kann schnell zu
                        unwohlsein führen. Gerade dann kann man mit den passenden Rezepten zum Beispiel Milch ersetzen, um
                        auf nichts verzichten müssen.<br/><br/>
                        <br/>
                        <br/>
                    </div>
                </Grid>
                <Grid item md={3}/>
            </Grid>
            {/*<Grid container>*/}
            {/*    <Grid item xs={12}></Grid>*/}
            {/*</Grid>*/}
            <Grid container>
                <Grid item xs={12}>

                </Grid>
            </Grid>

        </>
    );
};

export default Home;