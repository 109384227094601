import {postData} from "./Post";

export const getLink = async (dispatch, code = null, create = false) => {
    let data = {action: 'link'};
    if (code) {
        data = {action: 'link', link: code};
    }
    if (create) {
        data = {action: 'link', create: true};
    }
    return postData(data)
        .then(json => {
                const items = json;
                if (!create && code !== null) {
                    if (!items.success) {
                        dispatch({
                            type: 'SET_ERROR',
                            error: "Es ist ein Fehler beim verlinken der Accounts aufgetreten."
                        });
                    }
                    if (items.success) {
                        dispatch({
                            type: 'SET_INFO',
                            information: "Dein Account wurde erfolgreich verknüpft."
                        });
                    }
                } else {
                    if (items.data.links && items.data.links.length > 0) {
                        dispatch({
                            type: 'SET_ACCOUNT_LINKS',
                            value: items.data.links
                        });
                    }
                    if (!items.success) {
                        if (json.error) {
                            dispatch({
                                type: 'SET_ERROR',
                                error: json.error
                            });
                        }
                    }
                }


                return items;

            }
        )
        .catch(error => {
            console.log(error);
        });
}

