import React, {useContext} from "react";
import {Context} from "../../Store/Store";
import {getLink} from "../../api/getLink";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Redirect} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    buttons: {
        margin: '5px 0 0 0 ',
        backgroundColor: '#646f44',
        color: '#ffffff',
        fontWeight: '500',
        '&:hover': {
            backgroundColor: '#465128',
        },
        '&:active': {
            backgroundColor: '#646f44',
        },
    },
}));

const LinkAccounts = ({match}) => {
    const [dispatch] = useContext(Context);
    const classes = useStyles();
    return (
        <>
            {localStorage.getItem('ssid') === null &&
            <Redirect to={{
                pathname: "/login",
            }}/>}
            <Grid container>
                <Grid item md={2}/>
                <Grid item xs={12} md={8} style={{alignContent:'center', marginTop:'50px'}}>
                    Wenn Du Deinen Account verknüpfst wird Deine Einkaufsliste und Dein Essensplan ersetzt. <br /><br/>
                    <Button color={'primary'} className={classes.buttons} variant="contained"
                            onClick={() => getLink(dispatch, match.params.id)}>Account verknüpfen</Button>
                </Grid>
                <Grid item md={2}/>
            </Grid>
        </>
    );
};

export default LinkAccounts;