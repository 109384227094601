import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DialogActions from "@mui/material/DialogActions";

const useStyles = makeStyles((theme) => ({
    modal: {
        alignItems: 'center',
        padding:'30px'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #CCC',
        boxShadow: theme.shadows[5],
        padding: '30px',
        minHeight:'300px',
    },
}));

export default function ModalContainer({children, open, handleClose, Title, actions}) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                className={classes.modal}
                open={open}
                onClose={handleClose}
                fullScreen={fullScreen}
            >
                <DialogTitle id="alert-dialog-title">
                    {Title}
                    <span style={{position:'absolute', right:'15px', textAlign:'right', cursor:'pointer'}} >
                        <CloseIcon onClick={handleClose} />
                    </span>
                </DialogTitle>
                <DialogContent>
                   {children}
                </DialogContent>
                {typeof actions !== 'undefined' &&
                    <DialogActions>
                        {actions}
                    </DialogActions>
                }
            </Dialog>
        </div>
    );
}