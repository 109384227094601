import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../Store/Store";
import ChatRegister from "../Components/ChatRegister/ChatRegister";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {zutaten} from "../../api/zutaten";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {getSearch} from "../../api/getSearch";
import ModalContainer from "../Components/ModalContainer";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    inputField: {
        textDecoration: 'none',
        margin: '10px 0px',
    },
    buttons: {
        // margin: '5px 0 0 0 ',
        margin: '10px 0px',
        color: '#ffffff',
        fontWeight: '600',
        backgroundColor: '#646f44',
        '&:hover': {
            backgroundColor: '#465128',
        },
        '&:active': {
            backgroundColor: '#646f44',
        },
    },
}));

const Search = ({onChange}) => {
    const [state, dispatch] = useContext(Context);
    const classes = useStyles();
    const [zutat1, setZutat1] = useState(0);
    const [zutat2, setZutat2] = useState(0);
    const [zutat3, setZutat3] = useState(0);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (state.zutaten.length === 0) {
            zutaten(dispatch, state.sessionId)
        }
        // eslint-disable-next-line
    }, []);

    const searchSubmit = () => {
        let temp1 = parseInt(zutat1);
        let temp2 = parseInt(zutat2);
        let temp3 = parseInt(zutat3);
        if (temp1 > 0 || temp2 > 0 || temp3 > 0) {
            getSearch(dispatch, {zutat1: temp1, zutat2: temp2, zutat3: temp3}, state.sessionId).then(
                () => {
                    setOpen(true);
                }
            );
        }
    };

    return (
        <>
            <Helmet>
                <title>Rezept Suche - Plan Es(s)</title>
                <meta name="description"
                      content={"Du hast Zutaten übrig und weißt nicht was du machen kannst? Such dir jetzt Rezepte um deine Reste zu verwerten - Plan Es(s)"}/>
            </Helmet>

            <Grid container>
                <Grid item md={3}/>
                <Grid item md={6} xs={12}>
                    {typeof onChange === 'undefined' && <>
                        <h1>Rezept Suche</h1>

                        Du hast Zutaten übrig? Such dir passende Rezepte, um deine Reste zu verwerten.<br/>
                        Gib dazu einfach ein bis drei Zutaten ein und lass dir Rezepte vorschlagen.
                    </>}
                    <Grid container>
                        <Grid item xs={11}>
                            <Autocomplete
                                id="zutat1"
                                options={state.zutaten}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => {
                                    setZutat1(value !== null ? value.id : 0)
                                }}
                                renderInput={(params) => <TextField {...params} className={classes.inputField}
                                                                    variant={'outlined'} label="Erste Zutat"/>}
                            />
                        </Grid>
                        <Grid item xs={11}>
                            <Autocomplete
                                id="zutat2"
                                options={state.zutaten}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => {
                                    setZutat2(value !== null ? value.id : 0)
                                }}
                                renderInput={(params) => <TextField {...params} className={classes.inputField}
                                                                    variant={'outlined'} label="Zweite Zutat"/>}
                            />
                        </Grid>
                        <Grid item xs={11}>
                            <Autocomplete
                                id="zutat3"
                                options={state.zutaten}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => {
                                    setZutat3(value !== null ? value.id : 0)
                                }}
                                renderInput={(params) => <TextField {...params} className={classes.inputField}
                                                                    variant={'outlined'} label="Dritte Zutat"/>}
                            />
                        </Grid>
                        <Grid item xs={11}>
                            <Button fullWidth={true} style={{padding: '10px'}} className={classes.buttons}
                                    variant={'outlined'} id={'zutat3'}
                                    onClick={searchSubmit}>Rezept suchen</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={3}/>

            </Grid>

            <ModalContainer
                open={open}
                handleClose={() => setOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {!!state.search.rezepte ?
                    <>
                        Es wurden {state.search.found} Rezepte gefunden.
                        {state.search.rezepte.map((item) => {
                            return <ul>
                                {typeof onChange !== 'undefined' ?
                                    <li><span onClick={() => onChange(item.rezeptId)} style={{textDecoration:'underline',  color:'#666666', cursor:'pointer'}}>{item.name}</span></li>
                                    : <li><Link to={'/rezept/' + item.urlName}>{item.name}</Link></li>}
                            </ul>

                        })}
                    </>
                    :
                    <>
                        Es wurden {state.search.found} Rezepte gefunden, melde Dich jetzt an um die Liste zu sehen oder
                        registriere Dich.
                    </>
                }
            </ModalContainer>
            <ChatRegister/>
        </>
    );
};

export default Search;