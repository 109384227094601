import React, {useEffect, Fragment, useContext} from 'react';
import {Context} from "../../Store/Store";

function Responsive({ history, children }) {
    const [state, dispatch] = useContext(Context);

    const updateMedia = () => {
        if (state.isDesktop !== (window.innerWidth > 1250)) {
            dispatch({
                type: 'SET_IS_DESKTOP',
                value: window.innerWidth > 1250
            });
        }
    };
    useEffect(() => {
       updateMedia();
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });
    return <Fragment>{children}</Fragment>;
}

export default Responsive;