import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../Store/Store";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import {getNotizen} from "../../api/getNotizen";
import {Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";
import ModalContainer from "../Components/ModalContainer";
import Button from "@mui/material/Button";

const Notizen = ({match}) => {
    const [state, dispatch] = useContext(Context);
    const [addToZettel, setAddToZettel] = useState('');
    const [openDialog, setOpenDialog] = useState(false);


    useEffect(() => {
        getNotizen(dispatch, {}, state.sessionId);
        // eslint-disable-next-line
    }, []);


    const handleChange = (event) => {
        setAddToZettel(event.target.value);
    };
    const handleAdd = () => {
        getNotizen(dispatch, {addToZettel: addToZettel}, state.sessionId);
        setAddToZettel('');
    };
    const handleMutation = (ID, action) => {
        getNotizen(dispatch, {mutate: ID, mutation: action}, state.sessionId);
    };

    const deleteAll = () => {
        setOpenDialog(true);
    };

    const keyPress = (e) => {
        if (parseInt(e.keyCode) === 13) {
            // put the login here
            handleAdd()
        }
    };

    return (
        <>
            {localStorage.getItem('ssid') === null &&
            <Redirect to={{
                pathname: "/login",
                state: {referrer: match.url}
            }}/>}
            <Helmet>
                <title>Deine Notizen - Plan Es(s)</title>
                <meta name="description" content={"Notizen bei Plan Es(s), erstelle ganz einfach deinen eigenen Essensplan."} />
            </Helmet>
            <Grid container>
                <Grid item md={3}/>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item md={6} xs={8}><h1>Notizen</h1></Grid>
                        <Grid item md={6} xs={4}>
                            <div style={{textAlign: 'right', marginTop:'35px'}}>
                                <Button
                                    variant="outlined"
                                    onClick={deleteAll}
                                    style={{borderColor: 'rgb(100, 111, 68)', color: '#ffffff'}}
                                >
                                    {(state.isDesktop ? 'Notizen leeren' : 'Leeren')}
                                </Button></div>
                            <br/></Grid>
                    </Grid>
                    <Input
                        id="standard-adornment-password"
                        type={'text'}
                        value={addToZettel}
                        placeholder={'Zu deinen Notizen hinzufügen...'}
                        onChange={handleChange}
                        fullWidth
                        onKeyDown={keyPress}
                        endAdornment={
                            <IconButton
                                onClick={handleAdd}
                            >
                                <InputAdornment position="end">
                                    <CreateIcon/>
                                </InputAdornment>
                            </IconButton>
                        }
                    /><br/><br/>

                    {state.notizen.length > 0 ? state.notizen.map((item, index) => {
                        return <div key={Math.random()}>
                            <Grid item xs={12} style={{backgroundColor: (index % 2 === 1 ? 'rgb(139,170,66)' : 'rgba(100,111,68,1)')}}>
                                <Grid container>
                                    <Grid item xs={11}>
                                        <Checkbox onClick={() => handleMutation(item.ID, 'done')} checked={Boolean(parseInt(item.erledigt))} style={{color:'#00cc00'}}/> <span style={{textDecoration: (parseInt(item.erledigt)? 'line-through': 'none')}}>{item.position}</span>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton onClick={() => handleMutation(item.ID, 'delete')} style={{padding:'0px'}}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    }) : <>Deine Notizen sind leer</>}
                </Grid>
                <Grid item md={3}/>
            </Grid>

            <ModalContainer
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                Title={'Möchtest du sicher deine Notizen leeren?'}
                actions={<>
                    <Button onClick={() => setOpenDialog(false)}>Abbrechen</Button>
                    <Button onClick={() => {handleMutation(0, 'deleteAll'); setOpenDialog(false);}}>
                        Einkaufszettel leeren
                    </Button>
                </>} >
                Diese Aktion lässt sich nicht rückgängig machen.
            </ModalContainer>
        </>
    );
};

export default Notizen;