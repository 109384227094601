import {postData} from "./Post";

export const getEinkaufszettel = async (dispatch, einkaufszettel, sessionId) => {
    return postData({action: 'einkaufszettel', einkaufszettel: einkaufszettel}, sessionId)
        .then(json => {
            const items = json;

            if (json.success) {
                dispatch({
                    type: 'SET_EINKAUFSZETTEL',
                    einkaufszettel: json.data
                });

                return items;
            } else {
                if (json.error) {
                    dispatch({
                        type: 'SET_ERROR',
                        error: json.error
                    });
                }
                return [];
            }

        })
        .catch(error => {
            console.log(error);
        });
}

