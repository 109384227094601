import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../Store/Store";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import {Link, Redirect, useHistory} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from '@mui/material/Autocomplete';
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import {addRecipe} from "../../api/addRecipe";
import {editRecipe} from "../../api/editRecipe";
import ModalContainer from "../Components/ModalContainer";
import {getSearchRecipe} from "../../api/getSearchRecipe";
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
    inputField: {
        textDecoration: 'none',
        margin: '10px 10px',
        width: '100%'
    },
    buttons: {
        // margin: '5px 0 0 0 ',
        margin: '10px 10px',
        color: '#ffffff',
        fontWeight: '600',
        backgroundColor: '#646f44',
        '&:hover': {
            backgroundColor: '#465128',
        },
        '&:active': {
            backgroundColor: '#646f44',
        },
    },
}));

const AddRecipe = ({match}) => {
    const [state, dispatch] = useContext(Context);
    const classes = useStyles();
    const [inputList, setInputList] = useState([{id: null, menge: '', name: null, einheit: null}]);
    const [rezeptText, setRezeptText] = useState('');
    const [legal, setLegal] = useState(false);
    const [name, setName] = useState('');
    const [zubereitung, setZubereitung] = useState(0);
    const [mahlzeitTyp, setMahlzeitTyp] = useState(0);
    const [base64, setBase64] = useState('');
    const [userImage, setUserImage] = useState('');
    const [open, setOpen] = useState(false);
    const urlName = match.params.id;
    const history = useHistory();
    let tempVar = '';

    // handle input change
    const handleInputChange = (e, index, id, field) => {
        const {name, value} = e.target;
        const list = [...inputList];
        if (id !== undefined) {
            list[index][field] = id;
        } else {
            list[index][name] = value;
        }
        setInputList(list);
    };
    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, {id: 1, menge: "", einheit: ""}]);
    };
    const _handleReaderLoaded = (readerEvt) => {
        let binaryString = readerEvt.target.result;
        setUserImage('');
        setBase64(tempVar + btoa(binaryString))
        tempVar = '';
    }
    const onImageChange = event => {
        let file = event.target.files[0];
        let filename = file.name.toLowerCase().split('.');
        if (['jpg', 'png'].indexOf(filename[filename.length - 1]) !== -1) {
            if (file) {
                setUserImage('');
                if (filename[filename.length - 1] === 'jpg') {
                    setBase64("data:image/jpeg;base64, ");
                    tempVar = "data:image/jpeg;base64, ";

                }
                if (filename[filename.length - 1] === 'png') {
                    setBase64("data:image/png;base64, ");
                    tempVar = "data:image/png;base64, ";
                }
                const reader = new FileReader();
                reader.onload = _handleReaderLoaded
                reader.readAsBinaryString(file)
            }
        } else {
            dispatch({
                type: 'SET_ERROR',
                error: "Bitte ein Bild vom Format (jpg, png) auswählen."
            });
        }
    };

    const uploadRecipe = () => {
        if (name === '') {
            dispatch({
                type: 'SET_ERROR',
                error: "Bitte gib dem Rezept einen Namen."
            });
            return;
        }
        if (rezeptText === '') {
            dispatch({
                type: 'SET_ERROR',
                error: "Bitte gib einen Text für dein Rezept ein."
            });
            return;
        }
        if (base64 === '' && userImage === '') {
            dispatch({
                type: 'SET_ERROR',
                error: "Bitte ein Bild auswählen."
            });
            return;
        }

        if (inputList[0].id === '') {
            dispatch({
                type: 'SET_ERROR',
                error: "Es wurden keine Zutaten ausgewählt."
            });
            return;
        }

        getSearchRecipe(dispatch, name).then((result) => {

            let nameInUse = false;
            result.forEach((item) => {
                if (item.name === name) {
                    nameInUse = true;
                }
            });

            if (nameInUse) {
                dispatch({
                    type: 'SET_ERROR',
                    error: "Der gewählte Rezeptname ist bereits in Verwendung. Bitte wähle einen anderen."
                });
                return;
            }
            if (name !== '' && rezeptText !== '' && (base64 !== '' || userImage !== '')) {
                if (typeof urlName !== 'undefined') {
                    addRecipe(dispatch, {
                        name: name,
                        urlName: urlName,
                        zutaten: inputList,
                        rezeptText: rezeptText,
                        zubereitung: zubereitung,
                        base64,
                        userImage,
                        mahlzeitTyp
                    });
                } else {
                    addRecipe(dispatch, {name: name, zutaten: inputList, rezeptText: rezeptText, base64, mahlzeitTyp});
                }
                setRezeptText('');
                setInputList([{id: "", menge: "", einheit: ""}]);
                setLegal(false);
                setName('');
                setBase64('');
                setUserImage('');
                setMahlzeitTyp(0);
                setZubereitung(0);
                history.replace('../addrecipe')
            }
        });
    };

    const modalChoose = () => {
        editRecipe(dispatch, {}).then(() => {
            setOpen(true);
        });
    };

    const populate = (items) => {
        // const item = typeof state.editRezept[urlName] === 'undefined' ? items[urlName] : state.editRezept[urlName];
        const item = items[urlName] ? items[urlName] : state.editRezept[urlName];
        if (typeof item === 'undefined') {
            history.replace('../addrecipe');
            return;
        }
        setRezeptText(item.rezept);
        setName(item.Name);
        setBase64('');
        setUserImage(item.user_image);
        let zutaten = [];
        item.Zutaten.map((value => {
            zutaten.push({id: value.Id, name: value.name, menge: value.menge, einheit: value.einheit});
            return false;
        }));
        setInputList(zutaten);
        setZubereitung(item.Zeitaufwand);
        setMahlzeitTyp(item.mahlzeit_typ);
    };

    useEffect(() => {
        if (typeof urlName !== 'undefined') {
            editRecipe(dispatch, {urlName:urlName}).then((items) => {
                populate(items);
            });
        }
        // eslint-disable-next-line
    }, [urlName]);

    return (
        <>
            {localStorage.getItem('ssid') === null &&
            <Redirect to={{
                pathname: "/login",
                state: {referrer: match.url}
            }}/>}
            <Helmet>
                <title>Eigenes Rezept hinzufügen - Plan Es(s)</title>
                <meta name="description" content={"Eigene Rezepte ganz leicht hinzufügen bei Plan Es(s), erstelle ganz einfach deinen eigenen Essensplan."} />
            </Helmet>
            <Grid container>
                <Grid item md={3} key={'pleacehold1'}/>
                <Grid item md={6} xs={12} style={{padding: '20px'}} key={'content'}>
                    <div style={{textAlign: 'right'}}><Link to={'#'} onClick={modalChoose}
                                                            style={{color: '#ffffff', textDecoration: 'underline'}}>Eigenes
                        Rezept bearbeiten</Link><br/></div>
                    <br/>
                    Bitte füge die Zutaten für 2 Portionen ein. Das ermöglicht eine optimale Skalierung der Mengen.
                    <TextField type={'text'} placeholder={'Rezept Name'} name={'name'} label="Rezept Name" value={name}
                               className={classes.inputField} onChange={(event) => setName(event.target.value)}/>
                    {inputList.map((x, i) => {
                        return <Grid container style={{
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            marginLeft: '10px',
                            marginBottom: '5px',
                            padding: '0 20px 0 0'
                        }}>
                            <Grid item xs={12} md={4}>
                                <TextField type={'number'} placeholder={'Menge'} name={'menge'} label="Menge"
                                           value={inputList[i].menge}
                                           inputProps={{min: "0", step: "0.1"}} className={classes.inputField}
                                           onChange={(event) => handleInputChange(event, i)}/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {/*<TextField type={'text'} placeholder={'Einheit'} name={'einheit'} label="Einheit" value={inputList[i].einheit}*/}
                                {/*           className={classes.inputField} onChange={(event)=>handleInputChange(event, i)}/>*/}

                                <Autocomplete
                                    isOptionEqualToValue={(option, value) => {
                                        return option.id === value.id;
                                    }}
                                    style={{marginTop: '4px'}}
                                    key={'einheit' + i}
                                    id={'einheit' + i}
                                    name={'einheit'}
                                    value={inputList[i].einheit !== null ? {
                                        id: inputList[i].einheit,
                                        name: inputList[i].einheit
                                    } : null}
                                    placeholder={'Einheit'}
                                    label={"Einheit"}
                                    options={[
                                        {id: 'g', name: 'g'},
                                        {id: 'Stück', name: 'Stück'},
                                        {id: 'ml', name: 'ml'},
                                        {id: 'l', name: 'l'},
                                        {id: 'EL', name: 'EL'},
                                        {id: 'TL', name: 'TL'},
                                        {id: 'Prise', name: 'Prise'},
                                        {id: 'Kg', name: 'Kg'},
                                        {id: 'Pfund', name: 'Pfund'},
                                        {id: 'Tasse', name: 'Tasse'},
                                    ]}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, value) => {
                                        if (value !== null) {
                                            handleInputChange(event, i, value.id, 'einheit')
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} className={classes.inputField}
                                                                        name={'einheit'}
                                                                        placeholder={'Einheit'}
                                                                        label={"Einheit"}/>}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    isOptionEqualToValue={(option, value) => {
                                        return option.id === value.id;
                                    }}
                                    value={inputList[i].id !== null ? {
                                        id: inputList[i].id,
                                        name: inputList[i].name
                                    } : null}
                                    key={'zutat' + i}
                                    id={'zutat' + i}
                                    name={'id'}
                                    autoSelect={true}
                                    options={state.zutaten}
                                    style={{marginTop: '4px'}}
                                    getOptionLabel={(option) => typeof option.name === 'string' ? option.name : ''}
                                    onChange={(event, value) => {
                                        if (value !== null) {
                                            handleInputChange(event, i, value.id, 'id')
                                            handleInputChange(event, i, value.name, 'name')
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} className={classes.inputField}
                                                                        label="Zutat"/>}
                                />
                            </Grid>
                            <div className="btn-box">
                                {inputList.length !== 1 && inputList.length - 1 === i && <Button
                                    onClick={() => handleRemoveClick(i)}>Löschen</Button>}
                                {inputList.length - 1 === i && <Button onClick={handleAddClick}>Mehr Zutaten</Button>}
                            </div>
                        </Grid>
                    })}
                    <TextField
                        id="RezeptText"
                        label="Rezept Beschreibung"
                        multiline
                        rows={10}
                        className={classes.inputField}
                        placeholder="Hier kommt dein Rezepttext hin..."
                        variant="outlined"
                        value={rezeptText}
                        onChange={(event) => setRezeptText(event.target.value)}
                    />
                    <Autocomplete
                        id={'mahlezeitTyp'}
                        name={'mahlezeitTyp'}
                        options={[
                            {id: '1', name: 'Frühstück'},
                            {id: '2;4', name: 'Snack'},
                            {id: '3;6', name: 'Mittagessen / Abendessen'},
                            {id: '5', name: 'Kuchen'},
                        ]}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                            if (value !== null) {
                                setMahlzeitTyp(value.id)
                            }
                        }}
                        renderInput={(params) => <TextField {...params} className={classes.inputField}
                                                            label="Tageszeit"/>}
                    />

                    {base64 !== '' && <><img src={base64} width={120} height={120} alt={'User Rezept Bild'}/><br/></>}
                    {base64 === '' && userImage !== '' && <><img src={ (window.location.hostname === 'localhost' ? 'http://localhost/planess/public' : '') + userImage } width={120} height={120} alt={'User Rezept Bild'}/><br/></>}
                    <label htmlFor="upload-photo">
                        <input
                            style={{display: "none"}}
                            id="upload-photo"
                            accept="image/*"
                            name="upload-photo"
                            onChange={onImageChange}
                            type="file"
                        />
                        <Button
                            color="default"
                            size="small"
                            component="span"
                            aria-label="add"
                            variant="text"
                        >
                            <AddIcon/> Upload photo
                        </Button>
                    </label>
                    <br/> <br/>
                    <TextField type={'number'} placeholder={'Dauer der Zubereitung'} name={'dauer'} label="Dauer der Zubereitung" value={zubereitung}
                               inputProps={{min: "0", step: "1"}}
                               InputProps={{
                                   endAdornment:<InputAdornment position="end">Minuten</InputAdornment>
                               }}

                               className={classes.inputField} onChange={(event) => setZubereitung(event.target.value)}/>

                    <br/> <br/>
                    <Checkbox checked={legal} name={'legal'} onClick={() => setLegal(!legal)}/>
                    Hiermit bestätige ich, dass ich die Rechte am Rezept besitze und das Rezept veröffentlichen möchte.
                    <br/><br/>
                    <Button onClick={uploadRecipe} disabled={!legal} variant={"outlined"}>Speichern</Button>
                </Grid>
                <Grid item md={3} key={'pleacehold2'}/>
            </Grid>
            <ModalContainer
                open={open}
                handleClose={() => setOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                Title={'Deine Rezepte'}
            >
                {state.editRezept !== {} ?
                    <>
                        {Object.values(state.editRezept).map(val => {
                                return <ul>
                                    <li><Link to={'/addrecipe/' + val.urlName}
                                              onClick={() => setOpen(false)}>{val.Name}</Link></li>
                                </ul>
                            }
                        )}
                    </>
                    :
                    <>
                        Du hast noch keine Rezepte hinzugefügt die bearbeitet werden können, füge doch gleich eins
                        hinzu.
                    </>
                }
            </ModalContainer>

        </>
    );
};

export default AddRecipe;