import {postData} from "./Post";

export const getSettings = async (dispatch, settings) => {
    return postData({action: 'settings', settings: settings})
        .then(json => {

            if (json.success) {
                dispatch({
                    type: 'SET_SETTINGS',
                    settings: json.data
                });
                return true;
            } else {
                if (json.error) {
                    dispatch({
                        type: 'SET_ERROR',
                        error: json.error
                    });
                }
                return false;
            }

        })
        .catch(error => {
            console.log(error);
        });
}

