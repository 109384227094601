import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../Store/Store";
import {login as sendLogin} from "../../api/login";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Redirect, useLocation, Link} from "react-router-dom";
import ChatRegister from "../Components/ChatRegister/ChatRegister";

const useStyles = makeStyles((theme) => ({
    buttons: {
        margin: '5px 0 0 0 ',
        backgroundColor: '#646f44',
        color:'#ffffff',
        fontWeight:'500',
        '&:hover': {
            backgroundColor: '#465128',
        },
        '&:active': {
            backgroundColor: '#646f44',
        },
    },
}));

const Login = (match) => {
    const [state, dispatch] = useContext(Context);
    const classes = useStyles();
    const [dataArray, setDataArray] = useState({});
    const location = useLocation();


    useEffect(() => {
        if (state.sessionId) {
            sendLogin(dispatch, {});
        }
        // eslint-disable-next-line
    }, []);

    const startLogin = () => {
        sendLogin(dispatch,dataArray);
    }

    const onKeyUpHandle = (event) => {
        if (event.key === 'Enter') {
            sendLogin(dispatch,dataArray);
        }
    }

    const redirectTo = location.state && location.state.referrer ? location.state.referrer : '/essensplan';

    return (
        <>
            {state.user !== null &&
            <Redirect to={redirectTo} />}
            <Grid container>
                <Grid item xs={12} style={{textAlign:"center", padding:30}}>
                    Hier kannst du dich zu deinem persönlichen Essensplan einloggen. Nutze die Einkaufsliste, persönliche Notizen und die Rezeptsuche. Außerdem ist es dir möglich deinen Essensplan zu individualisieren.
                    <br />
                    <br />
                    <TextField color={'primary'} type={'email'} placeholder={'Email'}
                               style={{width:'50vw'}}
                               onChange={(event) =>
                               {setDataArray({...dataArray, 'email': event.target.value})}}
                               onKeyUp={onKeyUpHandle}
                    /><br/><br/>
                    <TextField color={'primary'} type={'password'} placeholder={'Passwort'}
                               style={{width:'50vw'}}
                               onChange={(event) =>
                               {setDataArray({...dataArray, 'password': event.target.value})}}
                                onKeyUp={onKeyUpHandle}
                    /><br/><br/>
                        <Link to={'/register'} style={{color:'#ffffff', textDecoration:'none', marginLeft: (state.isDesktop ? '30vw' : '10vw')}}>Kostenlos Registrieren</Link>
                    <Link to={'/forgot'} style={{color:'#ffffff', textDecoration:'none', marginLeft:'5vw'}}>Passwort vergessen</Link>
                    <br/><br/>
                    <Button color={'primary'} className={classes.buttons} variant="contained"
                            onClick={() => startLogin()}
                    >Login</Button>
                </Grid>
            </Grid>
            <ChatRegister/>
        </>
    );
};

export default Login;