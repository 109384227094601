import {postData} from "./Post";

export const saveRegister = async (dispatch, dataArray, sessionId) => {
    return postData({action: 'saveRegister', ...dataArray},sessionId)
        .then(json => {

            if (!json.success) {
                dispatch({
                    type: 'SET_ERROR',
                    error: "Bei der Registrierung ist ein Fehler aufgetreten."
                });
                if (json.error) {
                    dispatch({
                        type: 'SET_ERROR',
                        error: json.error
                    });
                }
            }
            if (json.success) {
                return true;
            }
        })
        .catch(error => {
            console.log(error);
        });
}

