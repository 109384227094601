import {postData} from "./Post";
import {getInitial} from "./getInitial";

export const login = async (dispatch, dataArray) => {
    return postData({action: 'login', ...dataArray})
        .then(json => {
            let data = json.data
            if (json.success) {
                dispatch({
                    type: 'SET_USER',
                    user: data.user,
                    sessionId: data.sessionId
                });
                return true;
            } else {
                localStorage.removeItem('ssid');
                dispatch({
                    type: 'SET_SSID',
                    sessionId: ''
                });
                if (data === 'error_credentials') {
                    dispatch({
                        type: 'SET_ERROR',
                        error: "Die Daten sind nicht korrekt."
                    });
                }
                if (data === 'empty_login') {
                    dispatch({
                        type: 'SET_ERROR',
                        error: "Bitte fülle deine Zugangsdaten aus."
                    });
                }
                if (json.error) {
                    dispatch({
                        type: 'SET_ERROR',
                        error: json.error
                    });
                }
            }
        }).then(() =>  {
            getInitial(dispatch);
        })
        .catch(error => {
            console.log(error);
        });
}

