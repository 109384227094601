import {postData} from "./Post";
import {getInitial} from "./getInitial";

export const forgotPassword = async (dispatch, dataArray) => {
    return postData({action: 'forgotPassword', ...dataArray})
        .then(json => {
            let data = json.data;
            if (json.success) {
                if (data === 'password changed') {
                    dispatch({
                        type: 'SET_INFO',
                        information: "Passwort erfolgreich geändert."
                    });
                } else {
                    dispatch({
                        type: 'SET_INFO',
                        information: "Falls du bereits registriert bist, bekommst du gleich eine E-Mail zum Zurücksetzen deines Passworts."
                    });
                }
            } else {
                if (json.error) {
                    dispatch({
                        type: 'SET_ERROR',
                        error: json.error
                    });
                }
            }
        }).then(() =>  {
            getInitial(dispatch);
        })
        .catch(error => {
            console.log(error);
        });
}

