import React from "react";


const Agb = ({match}) => {

    return (
        <div style={{padding:'40px'}}>
            <p><strong>Allgemeine Geschäftsbedingungen und wichtige Nutzerinformationen<br/>
                www.plan-ess.de</strong>
                <hr/>
            </p>
            <br/>
            <strong>I. Allgemeine Geschäftsbedingungen</strong><br/><br/>
            <strong>§ 1 Grundlegende Bestimmungen</strong><br/><br/>
            <ul>
                1.1 Die nachstehenden Geschäftsbedingungen (nachfolgend als "AGB" bezeichnet) gelten für alle
                Verträge zwischen Tobias Engert (Einzelunternehmer), Zu den Steinbrüchen 12b, 64853 Otzberg (nachfolgend
                als "Anbieter"
                bezeichnet) und dem Nutzer, welche über die unter der Adresse <a
                href="http://www.plan-ess.de">www.plan-ess.de</a> abrufbaren Internetpräsenz (nachfolgend als "plan-ess"
                bezeichnet) geschlossen werden. Vorbehaltlich einer schriftlichen Zustimmung
                durch den Anbieter wird der Einbeziehung von eigenen Bedingungen des Nutzers widersprochen.<br/> <br/>
                1.2 Diese AGB gelten auch für alle zukünftigen Verträge zwischen dem Anbieter und dem Nutzer über die
                auf plan-ess.de angebotenen Leistungen.
                <br/><br/>
            </ul>
            <strong>§ 2 Vertragsgegenstand</strong><br/><br/>
            <ul>
                2.1 Der Anbieter unterhält auf <a href="http://www.plan-ess.de">plan-ess.de</a> ein
                Online-Portal, das es dem Nutzer ermöglicht, Essenspläne und damit verbundene Inhalte zu
                erstellen.<br/><br/>
                2.2 Hinsichtlich der Einzelheiten und wesentlichen Merkmale der angebotenen Leistungen wird auf die
                jeweilige Leistungsbeschreibung und die ergänzenden Angaben auf plan-ess.de verwiesen.<br/><br/>
            </ul>
            <strong>§ 3 Vertragsschluss </strong><br/> <br/>

            <p>Der Nutzer erhält einen kostenlosen Zugang </p>
            <br/><br/>
            3.1.1 Der Nutzer hat die Möglichkeit, sich für einen kostenlosen Zugang zu registrieren. Für die
            Registrierung ist die E-Mail-Adresse des Nutzers erforderlich.<br/> <br/>
            3.1.2 Die Möglichkeit zur Registrierung auf www.plan-ess.de ist unverbindlich und stellt kein
            bindendes Angebot des Anbieters zum Vertragsschluss dar.<br/> <br/>
            3.1.3 Der Nutzer gibt ein verbindliches Angebot zum Abschluss eines Vertrages ab, wenn er nach Eingabe
            der E-Mail-Adresse und des Passwortes die Schaltfläche "Registrieren" betätigt. Vor Betätigung der
            Schaltfläche "Registrieren" hat der Nutzer die Möglichkeit, seine Eingaben zu korrigieren.<br/>
            <br/>
            3.1.4 Der Anbieter nimmt das Angebot des Nutzers zum Vertragsschluss innerhalb eines Zeitraums von drei
            Werktagen an. Nach Ablauf dieser Frist ist der Nutzer an sein Angebot nicht mehr gebunden. <br/> <br/>
            3.1.5 Der Anbieter nimmt das Angebot des Kunden durch Zusendung einer Registrierungsbestätigung an die
            vom Kunden bei der Registrierung angegebene E-Mail-Adresse an. Mit dem Zugang der
            Registrierungsbestätigung kommt ein verbindlicher Vertrag zwischen dem Anbieter und dem Nutzer zustande.
            <br/> <br/>

            <strong>§ 4 Pflichten des Nutzers </strong><br/> <br/>
            <ul>
                4.1 Die Inhalte des Onlineangebots dürfen ausschlie&szlig;lich für private Zwecke genutzt werden. Es ist
                dem Nutzer ausdrücklich untersagt, die vom Anbieter bereitgestellten Inhalte über die nach Ma&szlig;gabe
                dieses Vertrages erlaubte Nutzung hinaus zu nutzen oder in irgendeiner Weise Dritten privat oder
                kommerziell zur Verfügung zu stellen oder zugänglich zu machen. Eine &Uuml;bertragung der Nutzungsrechte
                durch den Nutzer an Dritte bedarf der vorherigen schriftlichen Genehmigung durch den
                Anbieter.<br/><br/>
                4.2 Die Abtretung von Ansprüchen aus dem Vertragsverhältnis an Dritte ist nur mit schriftlicher
                Zustimmung des Anbieters möglich.<br/>
                <br/></ul>

            <strong>§ 5 Vertragslaufzeit, Kündigung, Sperrung</strong>
            <br/><br/>
            <ul>
                5.1 Der Vertrag zwischen dem Anbieter und dem Nutzer beginnt mit Zustandekommen des Vertrages nach §
                3 dieser AGB und wird für die Dauer von einem Monat geschlossen. Kündigt keine der Parteien den
                Vertrag, verlängert er sich automatisch um einen weiteren Monat.
                <br/> <br/>
            </ul>
            <strong>§ 6 Erreichbarkeit des Online-Portals </strong><br/><br/>
            <ul>
                6.1 Der Anbieter gewährleistet eine Erreichbarkeit des Online-Portals von 98 % im Jahresmittel.
                Hiervon ausgenommen sind Zeiten, in denen der Server aufgrund von technischen oder sonstigen
                Problemen, die nicht im Einflussbereich des Anbieters liegen (höhere Gewalt, Verschulden Dritter
                etc.), nicht zu erreichen ist.<br/><br/>
                6.2 Der Anbieter ist berechtigt, den Funktionsumfang des Online-Portals und deren Struktur jederzeit
                zu erweitern und technisch anzupassen sowie Menüführungen oder Layouts zu verändern sowie sonstige
                Wartungsarbeiten vorzunehmen. Hierfür kann der Zugriff auf das Online-Portal längstens für die Dauer
                von 24 Stunden unterbrochen werden. &uuml;ber geplante &Auml;nderungen wird der Anbieter den Nutzer
                spätestens drei Tage im Voraus informieren.<br/><br/>
            </ul>

            <strong>§ 7 Nutzungsrechte</strong><br/><br/>
            <ul>
                7.1 Die durch den Anbieter zur Verfügung gestellten Inhalte, (z.B. Wochenpläne, Rezepte,
                Einkaufszettel, Vorratsschrank, Druckdateien, Text-, Audio- und Videoinhalte) sind urheberrechtlich
                geschützt. <br/><br/>
                7.2 Der Nutzer erhält an den von dem Anbieter auf dem Online-Portal bereitgestellten Inhalten ein
                nicht ausschlie&szlig;liches, nicht übertragbares, räumlich auf das Gebiet der Bundesrepublik
                Deutschland
                begrenztes und zeitlich auf die vereinbarte Vertragslaufzeit beschränktes Nutzungsrecht. Darüber
                hinausgehende Rechte erhält der Nutzer nicht. <br/><br/>
                7.3 Der Nutzer ist berechtigt, die von dem Anbieter auf dem Online-Portal bereitgestellten Text-,
                Audio- und Videoinhalte für die Dauer seiner Mitgliedschaft zu nutzen. Die bereitgestellten Audio-
                und Videoinhalte werden dem Nutzer über seinen persönlichen Mitglieder-Account ausschlie&szlig;lich zum
                Streaming bereitgestellt. Der Nutzer ist berechtigt, während seiner Mitgliedschaft diese Inhalte
                beliebig oft zu streamen. Jedwede darüber hinausgehende Verbreitung, Vervielfältigung oder
                öffentliche Zugänglichmachung, sei es durch Herunterladen (mit Ausnahme der Speicherung im
                Arbeitsspeicher im Rahmen des Streamings) oder Speicherung der bereitgestellten Inhalte ist ohne
                ausdrückliche Zustimmung des Urhebers nicht zulässig. Ferner ist der Nutzer nicht zur Bearbeitung
                oder Veränderung der auf dem Online-Portal bereitgestellten Inhalte berechtigt. <br/><br/>
            </ul>
            <strong>§ 8 Haftung, Haftungsbegrenzung und -ausschluss </strong><br/><br/>
            <ul>
                8.1 Für seinen Körper und wie dieser ernährt wird, trägt jeder selbst die Verantwortung. Im
                Zweifelsfall oder bei Beschwerden sollte ein Arzt oder Ernährungsberater zu Rate gezogen
                werden.<br/><br/>
                8.2 Manche Lebensmittel sind möglicherweise für Sie nicht geeignet. Sinnvollerweise suchen Sie vor
                Beginn einer Ernährungsumstellung ärztlichen Rat. Obwohl die Informationen im guten Glauben und nach
                bestem Wissen korrekt zusammengestellt wurden, macht plan-ess keine Zusicherungen oder
                Gewährleistungen hinsichtlich ihrer Vollständigkeit oder Genauigkeit. Der Gebrauch aller
                Informationen, einschlie&szlig;lich der Nährwerte, erfolgt auf eigenes Risiko. <br/><br/>
                8.3 Soweit die Haftung für Schäden dem Anbieter gegenüber ausgeschlossen oder eingeschränkt ist,
                gilt dies auch im Hinblick auf die persönliche Schadenersatzhaftung der Angestellten, Arbeitnehmer,
                Mitarbeiter, Vertreter und sonstigen Erfüllungsgehilfen des Anbieters.<br/>
            </ul>
            <br/>
            <strong>§ 9 Rechtswahl, Erfüllungsort, Gerichtsstand</strong><br/><br/>
            <ul>
                9.1 Es gilt deutsches Recht. Bei Verbrauchern gilt diese Rechtswahl nur, soweit hierdurch der durch
                zwingende Bestimmungen des Rechts des Staates des gewöhnlichen Aufenthaltes des Verbrauchers
                gewährte Schutz nicht entzogen wird (Günstigkeitsprinzip).<br/><br/>
                9.2 Erfüllungsort für alle Leistungen aus den mit uns bestehenden Geschäftsbeziehungen sowie
                Gerichtsstand ist der Sitz des Anbieters, soweit der Nutzer nicht Verbraucher, sondern Kaufmann,
                juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen ist. Dasselbe
                gilt, wenn der Nutzer keinen allgemeinen Gerichtsstand in Deutschland oder der EU hat, oder der
                Wohnsitz oder gewöhnliche Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt ist. Die Befugnis,
                auch das Gericht an einem anderen gesetzlichen Gerichtsstand anzurufen, bleibt hiervon
                unberührt.<br/><br/>
                9.3 Die Bestimmungen des UN-Kaufrechts finden ausdrücklich keine Anwendung.<br/><br/>
                <br/></ul>

            <strong>II. Nutzerinformationen </strong><br/>

            <br/><strong>Identität des Anbieters</strong><br/>
            <p>Tobias Engert<br/>
                Zu den Steinbrüchen 12b<br/>
                64853 Otzberg<br/>
                Deutschland<br/>
                <br/>
                Telefon: 0170 46 37 971<br/>
                E-Mail: info@plan-ess.de<br/>
            </p>
            <br/>
            <span>Die Europäische Kommission stellt eine Plattform für die au&szlig;ergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar unter <a
                href="http://ec.europa.eu/odr" rel="noreferrer" target="_blank">http://ec.europa.eu/odr</a>.</span><br/>
            <br/>
            <br/>
            <strong>2. Informationen zum Zustandekommen des Vertrages</strong><br/><br/>
            <p>Die technischen Schritte zum Vertragsschluss, der Vertragsschluss selbst und die
                Korrekturmöglichkeiten erfolgen nach Maßgabe des § 2 und 3 unserer Allgemeinen Geschäftsbedingungen
                (Teil I.).</p>
            Unsere Produkte können ohne weitere Plug-ins oder sonstige Software im Browser aufgerufen werden.
            Etwaige technische Schutzmaßnahmen werden nicht verwendet.
            <br/><br/>
            <strong>3. Vertragssprache, Vertragstextspeicherung</strong><br/><br/>

            3.1 Vertragssprache ist deutsch.<br/>
            Der vollständige Vertragstext wird beim Anbieter nicht gespeichert. Vor Absenden der Bestellung können
            die Vertragsdaten über die Druckfunktion des Browsers ausgedruckt oder elektronisch gesichert werden.
            Nach Zugang der Bestellung beim Anbieter werden die Vertragsdaten, die gesetzlich vorgeschriebenen
            Informationen bei Fernabsatzverträgen und die Allgemeinen Geschäftsbedingungen dem Nutzer nochmals per
            E-Mail übersandt.<br/><br/>
            3.2 Bei Anfragen, die schriftlich, telefonisch oder per E-Mail an den Anbieter übermittelt werden,
            erhält der Nutzer im Rahmen eines verbindlichen Angebots sämtliche Vertragsdaten sowie die gesetzlich
            vorgeschriebenen Informationen bei Fernabsatzverträgen und die Allgemeinen Geschäftsbedingungen
            übersandt. Soweit das Angebot in elektronischer Form (z.B. E-Mail) übermittelt wird, kann der Nutzer
            dieses ausdrucken und/oder elektronisch sichern.<br/>
            <br/>
            <strong>4. Wesentliche Merkmale der Dienstleistungen</strong><br/><br/>

            <p>Die wesentlichen Merkmale der Dienstleistung finden sich in der jeweiligen Leistungsbeschreibung und
                den ergänzenden Angaben auf der Internetseite des Anbieters.</p>
            <br/>

            <strong>5. Gesetzliches Mängelhaftungsrecht </strong><br/><br/>

            <p>Es bestehen die gesetzlichen Mängelhaftungsrechte.</p>
            <br/>
            <strong>6. Vertragslaufzeit und Kündigungsbedingungen </strong><br/><br/>

            <p>Informationen zur Laufzeit des Vertrages sowie den Bedingungen der Kündigung sind unter der Regelung
                § 5 "Vertragslaufzeit, Kündigung" in den AGB sowie in der jeweiligen Leistungsbeschreibung auf
                der Internetseite des Anbieters abrufbar.</p><br/><br/>

            August, 07. Oktober 2021
        </div>
    );
};

export default Agb;