import {postData} from "./Post";

export const getEinkaufszettelVorschlag = async (dispatch, text, sessionId) => {
    return postData({action: 'einkaufszettelVorschlag', einkaufszettel: text}, sessionId)
        .then(json => {
            if (json.success) {
                return json.data;
            } else {
                if (json.error) {
                    dispatch({
                        type: 'SET_ERROR',
                        error: json.error
                    });
                }
                return [];
            }

        })
        .catch(error => {
            console.log(error);
        });
}

